<template>
  <div class="modal-body" data-cy="annnoceoverblik-slet-modal">
    <BaseBesked v-if="generiskBackendFejl" :type="'error'" :titel="$t('annnoceoverblik.fejlbesked.slet')" :vis-ikon="true">
      {{ $t('annnoceoverblik.fejlbesked.tekst') }}
    </BaseBesked>
    <ContentfulRigTekst :document="$tm('indkoebsOverblikSletningBekraeftelseModal.broedtekst')" />
  </div>
  <div class="modal-footer">
    <button type="button" @click="sletIndkoebsoverbliksKladde()" class="button button-primary" data-cy="annnoceoverblik-slet-knap">
      {{ $t('annnoceoverblik-sletning-bekraeftelse.slet') }}
    </button>
    <button type="button" @click="modalStore.closeModal()" class="button button-secondary" data-cy="annnoceoverblik-annuller-knap">
      {{ $t('annuller.tekst') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';

import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import BaseBesked from '@/main/components/base/BaseBesked.vue';
import { useModalStore } from '@/main/stores/modal.state';
import { eformsUtil } from '@/main/utils/eforms-util';

const modalStore = useModalStore(window.pinia);
const generiskBackendFejl = ref(false);

const props = defineProps({
  noticeId: {
    type: String,
    required: true
  },
  noticeVersion: {
    type: String,
    required: true
  },
  tableData: {
    type: Array as PropType<AnnonceOverblikTableRow[]>,
    required: true
  },
  dropdownIndex: {
    type: Number,
    required: true
  },
  titel: {
    type: String,
    required: true
  }
});

async function sletIndkoebsoverbliksKladde() {
  try {
    const response = await eformsUtil.sletKladde(props.noticeId, props.noticeVersion, ref(props.tableData), ref(props.dropdownIndex), props.titel);
    if (!response) {
      generiskBackendFejl.value = true;
    } else {
      modalStore.closeModal();
    }
  } catch (error) {
    console.error('Det fejler: ', error);
    generiskBackendFejl.value = true;
  }
}
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>

import _ from 'lodash';
import { StateTree, defineStore } from 'pinia';

import { EformsConstants } from '@/main/constants/eforms-constants';
import { Komponent } from '@/main/enums/komponent.enum';
import { KonfigureretContent } from '@/main/models/eforms/KonfigureretContent';
import { KonfigureretNoticeType } from '@/main/models/eforms/KonfigureretNoticeType';
import { AendringsbekendtgoerelseSetting } from '@/main/models/eforms/aendringsbekendtgoerelseSetting';
import { KonfigureretXmlStructure } from '@/main/models/eforms/konfigureretXmlStructure';
import { BekendtgoerelseNoegle, EgenBekendtgoerelseDto, XmlStructure } from '@/main/models/generated';
import { Content, Field, FieldsModel, Metadata, NoticeType, NoticeTypeKonfiguration, NoticeTypeList, VisualModelNode } from '@/main/models/generated';
import { bekendtgoerelseService } from '@/main/services/bekendtgoerelse.service';
import { bekendtgoerelseKladdeService } from '@/main/services/eforms/bekendtgoerelse-kladde.service';
import { eformsFormularKonfigurationService } from '@/main/services/eforms/eforms-formular-konfiguration.service';
import { getBekendtgoerelseController } from '@/main/services/generated/bekendtgoerelse-controller/bekendtgoerelse-controller';
import { getFormularKonfigurationController } from '@/main/services/generated/formular-konfiguration-controller/formular-konfiguration-controller';
import { getSdkController } from '@/main/services/generated/sdk-controller/sdk-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { DEFAULT_NOTICE_TYPE, DEFAULT_SDK_VERSION, SDK_VERSION_VALGMULIGHEDER } from '@/main/stores/sdkVersionValgmuligheder';
import { dateUtil } from '@/main/utils/date-util';
import { eformsDataUtil } from '@/main/utils/eforms-data-util';
import { eformsUtil } from '@/main/utils/eforms-util';
import { eformsValidationUtil } from '@/main/utils/eforms-validation-util';
import { log } from '@/main/utils/logger-util';

interface EFormsStoreModel {
  model: VisualModelNode;
  bekendtgoerelseNoegle?: BekendtgoerelseNoegle;
  currentLanguageId?: string;
  highestContentCountMap: Record<string, number>;
  sdkVersion: string;
  noticeSubTypeId: string;
  noticeSubType?: NoticeType;
  noticeSubTypeList?: NoticeTypeList;
  developerMode: boolean;
  visKunObligatoriskeFelter: boolean;
  visBusinessTermId: boolean;
  aendringsbekendtgoerelse: AendringsbekendtgoerelseSetting;
  f: {
    data?: FieldsModel;
    dataMap?: Map<string, Field>;
    xmlMap?: Map<string, KonfigureretXmlStructure>;
    promise?: Promise<void> | null;
    loading: boolean;
    error?: any;
  };
  noticeTypeKonfiguration?: NoticeTypeKonfiguration;
  konfigureretNoticeType?: KonfigureretNoticeType;
  initPromise?: Promise<void> | null;
  formularValideringer: ValideringFejl;
  expandedAccordions: Map<string, boolean>;
  kladde: {
    lastSaveStartTime?: number;
    lastSavedAt?: number;
    lastSavedKladde?: string;
    continuousErrorCount: number;
    scheduled?: boolean;
  };
  genbrug?: {
    egneBekendtgoerelserMetadata?: EgenBekendtgoerelseDto[];
    valgtBekentgoerelseKey?: string;
    hasAutofilledCvr?: boolean;
  };
}

export interface ValideringFejl {
  feltFejl?: Map<string, ValideringFejlDetailer>;
  andreFejl?: ValideringFejlDetailer[];
  erValideringKoert?: boolean;
  erValideringUdenFejl?: boolean;
  erKompletFormularValideringTil: boolean;
}

export interface ValideringFejlDetailer {
  ruleId: string;
  details: string;
}

// Ville bruge Pick, men så kan vi ikke gemme et Map (expandedAccordions)
interface StoredEformsStoreModel {
  // Bemærk, vi har fjernet 'model'.
  sdkVersion: EFormsStoreModel['sdkVersion'];
  noticeSubTypeId: EFormsStoreModel['noticeSubTypeId'];
  visBusinessTermId: EFormsStoreModel['visBusinessTermId'];
  aendringsbekendtgoerelse: EFormsStoreModel['aendringsbekendtgoerelse'];
  developerMode: EFormsStoreModel['developerMode'];
  visKunObligatoriskeFelter: EFormsStoreModel['visKunObligatoriskeFelter'];
  expandedAccordions: [string, boolean][];
}

const defaultValue: EFormsStoreModel = {
  model: {},
  highestContentCountMap: {},
  currentLanguageId: 'DAN', // Default til dansk
  sdkVersion: DEFAULT_SDK_VERSION,
  noticeSubTypeId: DEFAULT_NOTICE_TYPE,
  visBusinessTermId: false,
  developerMode: false,
  visKunObligatoriskeFelter: true,
  aendringsbekendtgoerelse: AendringsbekendtgoerelseSetting.IKKE_AENDRINGSBEKENDTGOERELSE,
  f: {
    data: undefined,
    error: null,
    loading: false,
    promise: undefined
  },
  formularValideringer: {
    erValideringKoert: false,
    erKompletFormularValideringTil: false
  },
  expandedAccordions: new Map(),
  kladde: {
    continuousErrorCount: 0,
    scheduled: false
  },
  genbrug: {
    egneBekendtgoerelserMetadata: undefined,
    valgtBekentgoerelseKey: undefined,
    hasAutofilledCvr: false
  }
};

export const useEformsModelStore = defineStore('eformsModelStore', {
  state: (): EFormsStoreModel => defaultValue,
  persist: {
    storage: localStorage,
    serializer: {
      serialize: (value: StateTree) =>
        JSON.stringify({
          sdkVersion: value.sdkVersion,
          noticeSubTypeId: value.noticeSubTypeId,
          visBusinessTermId: value.visBusinessTermId,
          developerMode: value.developerMode,
          visKunObligatoriskeFelter: value.visKunObligatoriskeFelter,
          aendringsbekendtgoerelse: value.aendringsbekendtgoerelse,
          expandedAccordions: Array.from(value.expandedAccordions.entries())
        } as StoredEformsStoreModel),
      deserialize: (value: string) => {
        const restored: StoredEformsStoreModel = JSON.parse(value);
        if (SDK_VERSION_VALGMULIGHEDER.findIndex(x => x.value === restored.sdkVersion) === -1) {
          log(`Gemt SDK-version: ${restored.sdkVersion} er ikke længere supporteret, resetter ...`);
          return defaultValue;
        }
        return {
          sdkVersion: restored.sdkVersion,
          noticeSubTypeId: restored.noticeSubTypeId,
          visBusinessTermId: restored.visBusinessTermId ?? defaultValue.visBusinessTermId,
          developerMode: restored.developerMode ?? defaultValue.developerMode,
          visKunObligatoriskeFelter: restored.visKunObligatoriskeFelter ?? defaultValue.visKunObligatoriskeFelter,
          aendringsbekendtgoerelse: restored.aendringsbekendtgoerelse ?? defaultValue.aendringsbekendtgoerelse,
          f: {
            error: null,
            loading: false
          },
          formularValideringer: {
            erValideringKoert: false
          },
          expandedAccordions: restored.expandedAccordions !== undefined ? new Map(restored.expandedAccordions) : new Map()
        } as EFormsStoreModel;
      }
    },
    afterRestore: ctx => {
      console.log(`afterRestore: ${ctx.store.$id}`);
      ctx.store.$state.f = {
        data: undefined,
        error: null,
        loading: false,
        promise: undefined
      };
    }
  },
  actions: {
    async initNoticeTypeKonfiguration(): Promise<void> {
      if (this.noticeTypeKonfiguration != undefined) {
        return;
      }
      const config = AuthService.getConfig(Komponent.Udbud);
      this.noticeTypeKonfiguration = (await getFormularKonfigurationController().hentNoticeTypeKonfiguration(config)).data;
    },
    async initFields(newSdkVersion?: string): Promise<void> {
      if ((newSdkVersion && newSdkVersion != this.sdkVersion) || this.f.data == undefined) {
        // Undgå at hente data mere end en gang...
        if (this.f.data != undefined && this.sdkVersion == newSdkVersion) {
          return;
        }
        const versionToFetch = newSdkVersion ?? this.sdkVersion;

        if (!this.f.promise) {
          this.f.loading = true;
          const config = AuthService.getConfig(Komponent.Udbud);
          this.f.promise = getSdkController()
            .hentFieldsModelJson(versionToFetch, config)
            .then(response => {
              this.f.data = response.data;
              this.f.dataMap = new Map(this.f.data.fields.map(field => [field.id, field]));
              // Undlad at assign xmlMap til pinia-store før vi har brug for det for at undgå change detection.
              const xmlMap = eformsDataUtil.beregnXmlMap(response.data.xmlStructure, response.data.fields);
              // Assign ind i pinia store.
              this.f.xmlMap = xmlMap;
              this.f.error = null;
              this.sdkVersion = versionToFetch;
            })
            .catch(error => {
              this.f.error = error;
            })
            .finally(() => {
              this.f.loading = false;
              this.f.promise = null;
            });
        }

        return this.f.promise;
      }
    },
    async initNoticeTypeList(newSdkVersion?: string): Promise<void> {
      if (this.noticeSubTypeList != undefined && this.sdkVersion == newSdkVersion) {
        // Intet er nyt, undlad at gøre noget.
        return;
      }
      const config = AuthService.getConfig(Komponent.Udbud);
      const response = await getSdkController().listNoticeTypes(newSdkVersion ?? this.sdkVersion, config);
      this.noticeSubTypeList = response.data;
      console.log('Hentede: noticeSubTypeList');
    },
    async initAndSetNoticeType(newSdkVersion: string, noticeTypeId?: string): Promise<void> {
      if (this.noticeSubType != undefined && this.noticeSubTypeId == noticeTypeId && this.sdkVersion == newSdkVersion) {
        // Intet er nyt, undlad at gøre noget.
        return;
      }

      // Fetch notice type
      const config = AuthService.getConfig(Komponent.Udbud);
      const response = await getSdkController().hentNoticeType(newSdkVersion, noticeTypeId ?? this.noticeSubTypeId, config);

      this.noticeSubType = response.data;
      this.noticeSubTypeId = this.noticeSubType.noticeId;
    },
    async initEgneBekendtgoerelser(): Promise<void> {
      if (this.genbrug?.egneBekendtgoerelserMetadata !== undefined) {
        return;
      }

      try {
        const response = await bekendtgoerelseService.hentEgneBekendtgoerelser();
        if (response !== undefined) {
          this.genbrug = {
            egneBekendtgoerelserMetadata: response,
            valgtBekentgoerelseKey: this.genbrug?.valgtBekentgoerelseKey
          };
        }
      } catch (err) {
        console.warn(`Kunne ikke hente egne bekendtgørelser: ${JSON.stringify(err)}`);
      }
    },
    async init(
      noticeTypeId?: string,
      sdkVersion?: string,
      forceRebuildModel?: boolean,
      erAendringsbekendtgoerelse?: AendringsbekendtgoerelseSetting,
      aendringsbekendtgoerelseAf?: BekendtgoerelseNoegle,
      kladdeBekendtgoerelseNoegle?: BekendtgoerelseNoegle,
      defaultValues?: Record<string, string> | undefined
    ): Promise<void> {
      // Undgå at init kan køre mere end en gang samtidig
      if (this.initPromise) {
        return this.initPromise;
      }
      let promiseResolve;
      this.initPromise = new Promise(resolve => {
        promiseResolve = resolve;
      });

      const skalRebuildModel =
        forceRebuildModel ||
        (sdkVersion != undefined && sdkVersion != this.sdkVersion) ||
        (noticeTypeId != undefined && noticeTypeId != this.noticeSubTypeId) ||
        this.model.contentId == undefined ||
        (erAendringsbekendtgoerelse != undefined && erAendringsbekendtgoerelse != this.aendringsbekendtgoerelse) ||
        aendringsbekendtgoerelseAf !== undefined;

      const newSdkVersion = sdkVersion ?? this.sdkVersion;
      this.aendringsbekendtgoerelse = erAendringsbekendtgoerelse ?? this.aendringsbekendtgoerelse;

      const skalBrugeKladde =
        kladdeBekendtgoerelseNoegle?.noticeId?.value !== undefined &&
        kladdeBekendtgoerelseNoegle.noticeId.value !== '' &&
        kladdeBekendtgoerelseNoegle?.noticeVersion?.value !== undefined &&
        kladdeBekendtgoerelseNoegle?.noticeVersion.value !== '';

      if (skalBrugeKladde) {
        const kladdeVm = await bekendtgoerelseKladdeService.hentKladde(kladdeBekendtgoerelseNoegle);
        if (noticeTypeId !== kladdeVm.noticeSubType) {
          const fejlbesked = `NoticeSubType på kladde: ${this.noticeSubTypeId} er forskellig fra den vi er ved at loade: ${kladdeVm.noticeSubType}`;
          console.error(fejlbesked);
          throw new Error(fejlbesked);
        }
        this.model = kladdeVm;
        this.kladde.lastSavedAt = Date.now() - bekendtgoerelseKladdeService.SAVE_INTERVAL_IN_MS;
        this.highestContentCountMap = initHighestContenCountMap(this.model);
        console.log(
          `Gendannede state fra kladde: '${kladdeBekendtgoerelseNoegle.noticeId?.value}' '${kladdeBekendtgoerelseNoegle.noticeVersion?.value}'`
        );
        this.saetNoticeId();
        if (this.model.aendringsbekendtgoerelseSetting !== undefined) {
          console.log(
            'Kladde har aendringsbekendtgoerelseSetting er sat til: ' +
              this.model.aendringsbekendtgoerelseSetting +
              ' init kald har: ' +
              erAendringsbekendtgoerelse
          );
          if (this.model.aendringsbekendtgoerelseSetting !== erAendringsbekendtgoerelse) {
            console.log('Kladde og init-kald stemmer ikke overens. Anvender kladdeens indstilling.');
          }
        }
      }

      // Vi skal hente fields og notice type.
      const fieldsPromise = this.initFields(newSdkVersion);
      const noticeSubTypeListPromise = this.initNoticeTypeList(newSdkVersion);
      const ntkPromise = this.initNoticeTypeKonfiguration();
      const egneBekendtgoerelserPromise = this.initEgneBekendtgoerelser();
      await this.initAndSetNoticeType(newSdkVersion, noticeTypeId);
      if (this.noticeSubType == null) {
        throw new Error('Notice sub type skal være sat nu ellers gik noget grueligt galt!');
      }

      // Opdater SDK version
      this.sdkVersion = newSdkVersion;

      // Undlad at returnere før fields er færdig.
      await fieldsPromise;
      await noticeSubTypeListPromise;
      await ntkPromise;
      await egneBekendtgoerelserPromise;

      if (skalRebuildModel || this.konfigureretNoticeType == undefined) {
        this.konfigureretNoticeType = eformsFormularKonfigurationService.konfigurerNoticeType(
          this.noticeSubType,
          this.noticeTypeKonfiguration!,
          this.f.dataMap!,
          this.aendringsbekendtgoerelse
        );
      }

      if (skalRebuildModel && !skalBrugeKladde) {
        // Rebuild kun model hvis den ikke allerede er bygget, eller hvis det er forced.
        // Vi eagar instantierer vores visual model ud fra noticeType. Således den er komplet ASAP og kan sendes til backenden når vi vil.¨
        await this.resetModel(defaultValues);
        if (this.bekendtgoerelseNoegle == undefined) {
          throw new Error('bekendtgoerelseNoegle skal være defineret');
        }
        console.warn('Sætter this.model.aendringsbekendtgoerelseSetting til: ' + erAendringsbekendtgoerelse);
        this.model.aendringsbekendtgoerelseSetting = erAendringsbekendtgoerelse;
      }

      if (this.model.aendringsbekendtgoerelseSetting == AendringsbekendtgoerelseSetting.AENDRINGSBEKENDTGOERELSE_EFORMS && !skalBrugeKladde) {
        console.log('Vi bygger en ændringsbekendtgørelse ud fra eksistereende bekendtgørlse.');

        if (aendringsbekendtgoerelseAf?.noticeId?.value == undefined || aendringsbekendtgoerelseAf?.noticeVersion?.value === undefined) {
          throw new Error('aendringsbekendtgoerelseAf var dårlig: ' + JSON.stringify(aendringsbekendtgoerelseAf));
        }
        // 1. Hent bekendtgørelse
        const vm = await bekendtgoerelseService.hentBekendtgoerelseSomVisualModel(aendringsbekendtgoerelseAf);

        // 2. Apply ændringer
        for (const kc of this.konfigureretNoticeType.content) {
          this.patchVisualModel(
            kc,
            vm,
            [
              'BT-04-notice', // Procedure ID
              'BT-03-notice',
              'BT-02-notice',
              'OPP-070-notice',
              'BT-01-notice'
            ],
            ['GR-Change']
          );
        }

        // 3. Tilføj data til GR-Change
        // 3.1: Sæt 'BT-758-notice' til 'værdier for Notice Identifier (id "BT-701-notice") og Version Identifier (id "BT-757-notice")'
        const bt758noticeDestinationVisualModel = eformsDataUtil.findVisualModelNodeByPredicateRec(
          this.model,
          vmNode => vmNode.contentId === 'BT-758-notice'
        );
        if (bt758noticeDestinationVisualModel === undefined) {
          throw new Error('Skal være defineret for en ændringsbekendtgørelse...');
        }
        bt758noticeDestinationVisualModel.value = `${aendringsbekendtgoerelseAf.noticeId.value}-${aendringsbekendtgoerelseAf.noticeVersion.value}`;
      }

      if (this.model.aendringsbekendtgoerelseSetting == AendringsbekendtgoerelseSetting.AENDRINGSBEKENDTGOERELSE_BLANK) {
        // MUD-2227: AK3: feltet Procedure Legal Basis (id "BT-01-notice") vises som et redigerbart forvalgsliste-felt med værdierne fra eForms kodelisten "legal-basis-dk". feltet er ikke forudfyldt med en defaultværdi.

        for (const f of ['BT-01-notice', 'BT-04-notice']) {
          const kc = eformsDataUtil.findKonfigureretContentRec(this.konfigureretNoticeType.content, f);
          if (kc == undefined) {
            console.warn(`'${f}' kunne ikke blive fundet og konfigureret for AENDRINGSBEKENDTGOERELSE_BLANK`);
          } else {
            kc.readOnly = false;
            console.log(`AENDRINGSBEKENDTGOERELSE_BLANK: Sætter '${f}' til at være readOnly=false`);
          }
        }
      }

      if (!skalBrugeKladde) {
        if (this.bekendtgoerelseNoegle == undefined) {
          throw new Error('bekendtgoerelseNoegle skal være defineret');
        }
        await bekendtgoerelseKladdeService.opretKladde(this.bekendtgoerelseNoegle, this.model);
      }

      // Vær sikker på at vi har sat currentSprog til den rette værdi.
      const currentSprogNode = eformsDataUtil.findInModel([this.model], 'BT-702(a)-notice');
      console.log('Vi skifter sprog til: ' + currentSprogNode?.value);
      this.skiftSprog(currentSprogNode?.value ?? 'DAN');

      // Resolve promise, så alle der venter kan fortsætte
      promiseResolve!();
      this.initPromise = null;
    },
    async validerModelHvisValideringErTil(forceValider = false) {
      // Opdater kladde som promise
      if (this.bekendtgoerelseNoegle == undefined) {
        throw new Error('bekendtgoerelseNoegle skal være defineret');
      }
      const opdaterKladdePromise = bekendtgoerelseKladdeService.debouncedOpdaterKladde(this.bekendtgoerelseNoegle);
      if (!forceValider && !this.formularValideringer?.erKompletFormularValideringTil) {
        console.log(`validerModel kaldt, men 'erKompletFormularValideringTil' er: ${this.formularValideringer?.erKompletFormularValideringTil}`);
        return;
      }
      // Kald backend få validering
      const config = AuthService.getConfig(Komponent.Udbud, false);
      const modelKopi = JSON.parse(JSON.stringify(this.model)) as VisualModelNode;
      eformsDataUtil.fjernOrphans(modelKopi);
      this.formularValideringer = {
        erKompletFormularValideringTil: true,
        erValideringKoert: false,
        feltFejl: this.formularValideringer.feltFejl, // Undgå at opdatere disse værdier
        andreFejl: this.formularValideringer.andreFejl // Undgå at opdatere disse værdier
      };
      const result = await getBekendtgoerelseController().valider1(this.sdkVersion, modelKopi, config);
      const feltFejl: Map<string, ValideringFejlDetailer> = new Map();
      const andreFejl: ValideringFejlDetailer[] = [];
      if (result.data.schematronValideringsFejlDto) {
        for (const f of result.data.schematronValideringsFejlDto) {
          const detailjer = {
            details: f.details,
            ruleId: f.ruleId
          } as ValideringFejlDetailer;

          if (f.contentType == 'node') {
            const nodeId = f.contentId != '' ? f.contentId : f.nodeId;
            console.log(`${nodeId} er node => andreFejl`);
            // Oversæt fra ND- => GR-
            if (nodeId?.startsWith('ND-')) {
              const r = eformsDataUtil.expandXmlNodeToFields(nodeId);
              const contentOrder = [] as string[];
              for (const c of this.konfigureretNoticeType!.content!) {
                contentOrder.push(...eformsDataUtil.contentOrder(c));
              }
              const intersection = _.intersection(r, contentOrder);
              const logMessage = `${nodeId}: alle felter i xml: ${JSON.stringify(
                r
              )} ............. felter i xml og i denne notice type: ${JSON.stringify(intersection)}`;

              if (intersection.length == 0) {
                console.warn(logMessage);
              } else {
                console.debug(logMessage);
              }
            }
            const key = `${nodeId}` + (f.contentCount != '' ? `-${f.contentCount}` : '');
            feltFejl.set(key, detailjer);
          } else if (f.contentId !== undefined && f.contentId !== '' && f.contentCount !== undefined && f.contentCount !== '') {
            feltFejl.set(`${f.contentId}-${f.contentCount}`, detailjer);
          } else {
            if (f.details !== undefined) {
              try {
                const parsedDetails = JSON.parse(f.details) as {
                  see?: string;
                  contentCount?: number;
                  diagnostic?: string;
                };
                if (parsedDetails?.see?.startsWith('node')) {
                  console.warn(`${f.contentId} er node => andreFejl`);
                } else if (parsedDetails.see != undefined) {
                  const seeSplit = parsedDetails.see.split(':');
                  if (seeSplit.length == 2) {
                    const btId = seeSplit[1];
                    if (parsedDetails.contentCount !== undefined) {
                      feltFejl.set(btId + '-' + parsedDetails.contentCount, detailjer);
                      console.log('Kunne tilknytte anden fejl til specifik felt: ' + btId);
                    } else {
                      feltFejl.set(btId, detailjer);
                      console.log('Kunne tilknytte anden fejl til uspecifik felt: ' + btId);
                    }
                    // Undlad at tilføje til andreFejl ...
                    continue;
                  }
                }
              } catch (err) {
                // Vi undlader at gøre noget her, lader den bare falde tilbage til andreFejl.
              }
            }
            andreFejl.push(detailjer);
          }
        }
      }

      const straksvalideringer = eformsValidationUtil.validateNumberFields();

      // Opdater datastore
      this.formularValideringer = {
        erValideringKoert: true,
        erValideringUdenFejl: feltFejl.size === 0 && andreFejl.length === 0,
        feltFejl: new Map([...straksvalideringer, ...feltFejl]), // Bemærk overskrives fra venstre mod højre
        andreFejl: andreFejl,
        erKompletFormularValideringTil: this.formularValideringer.erKompletFormularValideringTil
      } as ValideringFejl;
      // Vær sikker på at vi har ventet på promiset
      await opdaterKladdePromise;
    },
    clearModel() {
      this.model = defaultValue.model;
      this.highestContentCountMap = defaultValue.highestContentCountMap;
      this.developerMode = false;
    },
    beregnModel(defaultValues?: Record<string, string> | undefined): VisualModelNode {
      console.time('beregnModel');

      if (this.f.dataMap === undefined) {
        throw new Error('Fields dataMap burde ikke kunne være null efter this.f.promise er resolved. Tjek om fields.json blev hentet korrekt');
      }
      // Hvis vi bruger this.highestContentCountMap her, så vil der ske en masse change tracking. Dette gør løsningen meget langsom i nogle tilfælde.
      // I stedet laver en et midlertidigt map og assigner det bagefter
      const tempMap = {};
      const model = {
        contentId: 'notice-root',
        visNodeId: EformsConstants.StandardIdentifiers.ND_ROOT,
        visType: EformsConstants.VIS_TYPE_NON_FIELD,
        contentCount: '1',
        sdkVersion: `${this.noticeSubType!.metadataDatabase.version}`,
        noticeSubType: this.noticeSubType!.noticeId,
        noticeUuid: undefined, // Værdi sættes senere...
        children: [
          mapMetadata(this.noticeSubType!, this.noticeTypeKonfiguration!, this.noticeSubTypeList!),
          mapContent(
            this.noticeSubType!.content,
            this.noticeTypeKonfiguration!,
            tempMap,
            this.f.dataMap,
            this.noticeSubType,
            this.noticeSubTypeList,
            this.aendringsbekendtgoerelse
          )
        ]
      };
      // Brug det midlertidige map i modellen.
      this.highestContentCountMap = tempMap;
      console.timeEnd('beregnModel');
      if (defaultValues !== undefined) {
        for (const [id, value] of Object.entries(defaultValues)) {
          const node = eformsDataUtil.findInModel(model.children, id);
          if (value === undefined) {
            console.log("Satte ikke default værdi for '" + id + "' da value var undefined.");
          } else if (node !== undefined) {
            node.value = value;
            console.log("Sætter default værdi for '" + id + "' til '" + value + "'");
          } else {
            console.error("Kunne ikke sætte default værdi for '" + id + "' til '" + value + "'");
          }
        }
      }
      return model;
    },
    async resetModel(defaultValues?: Record<string, string> | undefined) {
      const promises = [] as Promise<void>[];
      if (!this.noticeSubType) {
        promises.push(this.initAndSetNoticeType(this.sdkVersion, this.noticeSubTypeId));
      }
      if (!this.noticeTypeKonfiguration) {
        promises.push(this.initNoticeTypeKonfiguration());
      }
      if (!this.f.data) {
        promises.push(this.initFields());
      }
      if (!this.noticeSubTypeList) {
        promises.push(this.initNoticeTypeList());
      }
      if (this.genbrug) {
        // Clear liste inden vi kalder init igen.
        this.genbrug!.egneBekendtgoerelserMetadata = undefined;
      }
      if (!this.genbrug?.egneBekendtgoerelserMetadata) {
        promises.push(this.initEgneBekendtgoerelser());
      }
      await Promise.allSettled(promises);

      this.highestContentCountMap = {};
      // Await fields promise hvis det findeds, så vi kan være sikker på at this.f.dataMap er sat.
      if (this.f.promise !== null) {
        await this.f.promise;
      }
      if (this.f.dataMap === undefined) {
        throw new Error('Fields dataMap burde ikke kunne være null efter this.f.promise er resolved. Tjek om fields.json blev hentet korrekt');
      }

      if (this.genbrug) {
        this.genbrug.hasAutofilledCvr = false;
      }
      // Nulstil model inden beregning, da dele af "beregnModel" kigger på nuværende model.
      this.model = {};

      this.model = this.beregnModel(defaultValues);
      this.saetNoticeId();
    },
    saetNoticeId() {
      const noticeUuid = this.model.children![0].children?.find(x => x.contentId === 'BT-701-notice')?.value;
      const noticeVersion = this.model.children![0].children?.find(x => x.contentId === 'BT-757-notice')?.value;
      if (noticeUuid === undefined) {
        throw new Error('Vi har lige reset model, men har ingen noticeUuid');
      }
      this.model.noticeUuid = noticeUuid;
      this.bekendtgoerelseNoegle = {
        noticeId: { value: noticeUuid },
        noticeVersion: { value: noticeVersion }
      } as BekendtgoerelseNoegle;
      console.log('Opdaterer eformsModelStore til at have noticeId og version: ' + JSON.stringify(this.bekendtgoerelseNoegle));
    },
    toggleDeveloperMode() {
      this.developerMode = !this.developerMode;
      console.log(`Dev mode?: ${this.developerMode}`);
    },
    toggleBusinessTermId() {
      this.visBusinessTermId = !this.visBusinessTermId;
    },
    toggleObligatoriskeFelter() {
      this.visKunObligatoriskeFelter = !this.visKunObligatoriskeFelter;
    },
    toggleKompletFormularValidering() {
      console.log(`toggleKompletFormularValidering => ${this.formularValideringer.erKompletFormularValideringTil}`);
      if (this.formularValideringer === undefined || this.formularValideringer.erKompletFormularValideringTil === false) {
        this.validerModelHvisValideringErTil(true);
      } else {
        this.formularValideringer = {
          erKompletFormularValideringTil: false,
          erValideringKoert: false,
          erValideringUdenFejl: false,
          andreFejl: undefined,
          feltFejl: undefined
        };
      }
    },
    updateModelById(contentId: string, value: string) {
      const toUpdate = eformsDataUtil.findInModel(this.model.children!, contentId);
      console.log(`📘 Opdater data: '${value}' - old object: ${JSON.stringify(toUpdate)}`);
      if (toUpdate) {
        toUpdate.value = value;
        return;
      }
      console.error(`Kunne ikke opdatere data. ID: ${contentId} blev ikke fundet i visual model.`);
    },
    updateModel(content: Content, value: string) {
      this.updateModelById(content.id, value);
    },
    addRepeatable(content: KonfigureretContent, contextNode: VisualModelNode, validateMaybe = true): VisualModelNode[] {
      const nyRepeatable = eformsDataUtil.addRepeatableImpl(
        content,
        contextNode,
        this.model,
        this.noticeSubType!,
        this.noticeSubTypeList!,
        this.noticeTypeKonfiguration!,
        this.highestContentCountMap,
        this.f.dataMap!
      );

      if (validateMaybe) {
        this.validerModelHvisValideringErTil();
      }
      return nyRepeatable;
    },
    removeRepeatable(nodeToRemove: VisualModelNode, validateMaybe = true) {
      eformsDataUtil.removeRepeatableImpl(nodeToRemove, this.model, this.konfigureretNoticeType!);

      if (validateMaybe) {
        this.validerModelHvisValideringErTil();
      }
    },
    findNaesteVaerdiMedIdScheme(konfigureretContent: KonfigureretContent | Content): string {
      // På sigt skulle denne ikke kigge på this.model, da man kan være ved at resette osv.
      const allSiblings = eformsDataUtil.findAllDeepVisualModelNodes(konfigureretContent, this.model);
      const allValues = allSiblings.filter(x => x.value !== '').map(x => +x.value!.split('-')[1]);
      if (allValues.length > 0) {
        const maxValue = allValues.reduce((prev, current) => (prev && prev > current ? prev : current));
        return `${maxValue + 1}`.padStart(4, '0');
      }
      return '0001';
    },
    patchVisualModel(kcToReplace: KonfigureretContent, vm: VisualModelNode, forceOpdaterMetadata?: string[], skipKonfigureretContentId?: string[]) {
      // kcToReplace: Det konfigueret content som ønsker at overskrives.
      // vm: Det visual model data som skal bruges som udgangspunktet for overskrivningen
      console.log(`patchVisualModel => ${kcToReplace.id}`);

      // Udfør ændringer på en kopi, som senere bliver replaced fuldstændig.
      const modelKopi: VisualModelNode = JSON.parse(JSON.stringify(this.model));

      // 1. Gemmemgå kc
      //    1. Find data i VM
      //    2. Håndter flyttesPaaTvaersAfXml (på samme måde som addRepeatable?)
      // const srcVmNode = eformsDataUtil.findInModel([vm], kcToReplace.visualModelParentId);
      // const destVmNode = eformsDataUtil.findInModel([modelKopi], kcToReplace.visualModelParentId);
      eformsDataUtil.patchDataRekursivt(kcToReplace, vm, vm, modelKopi, modelKopi, forceOpdaterMetadata, skipKonfigureretContentId);

      // Udskift modellen med ny replaced udgave.
      this.model = modelKopi;

      // Valider efter patch
      this.validerModelHvisValideringErTil();
    },
    rydVisualModelData(kcToClear: KonfigureretContent): void {
      console.log(`rydVisualModelData => ${kcToClear.id}`);

      // Lav ren model vi kan udføre replacement med
      const renModel = this.beregnModel();

      // Opdater model.
      this.patchVisualModel(kcToClear, renModel);
    },
    isKladdeDirty(): boolean {
      if (this.kladde.lastSavedKladde == undefined) {
        return true;
      }
      return JSON.stringify(this.model) != this.kladde.lastSavedKladde;
    },
    updateKladdeBookkeepingSaveInProgress(): void {
      this.kladde.scheduled = false;
      this.kladde.lastSavedAt = Date.now();
    },
    updateKladdeBookkeepingSaveDone(): void {
      this.kladde.lastSavedKladde = JSON.stringify(this.model);
      this.kladde.lastSavedAt = Date.now();
      this.kladde.continuousErrorCount = 0;
      this.kladde.scheduled = false;
    },
    incrementKladdeErrorCounter(): void {
      this.kladde.continuousErrorCount += 1;
      this.kladde.scheduled = false;
    },
    skiftSprog(nytSprog: string): void {
      // Hvis brugeren ændrer 'BT-702(a)-notice' så skal vi opdatere alle attributterne med navn languageID til det nye sprog, så formularen er konsistent.
      this.currentLanguageId = nytSprog;

      const alleFelterDerOpdateres: VisualModelNode[] = [];
      eformsDataUtil.findAllVisualModelNodeByPredicateRec(
        this.model,
        vm => {
          const field = this.f.dataMap?.get(vm.contentId!);
          return field != undefined && field.attributeName == 'languageID';
        },
        alleFelterDerOpdateres
      );

      for (const vm of alleFelterDerOpdateres) {
        vm.value = nytSprog;
      }
      console.log(`Opdateret formular til nyt sprog: '${nytSprog}'`);
    }
  },
  getters: {
    findData: (state: EFormsStoreModel) => (id: string) => eformsDataUtil.findInModel(state.model.children!, id),
    getNoticeId: (state: EFormsStoreModel) => () => eformsDataUtil.findInModel(state.model.children!, 'BT-701-notice')?.value ?? '',
    getNoticeVersion: (state: EFormsStoreModel) => () => eformsDataUtil.findInModel(state.model.children!, 'BT-757-notice')?.value ?? '01',
    getNoticePublicationNumber: (state: EFormsStoreModel) => () => eformsDataUtil.findInModel(state.model.children!, 'OPP-010-notice')?.value ?? '',
    findDataMedContentCount: (state: EFormsStoreModel) => (id: string, contentCount: string) =>
      eformsDataUtil.findInModelMedContentCount(state.model.children!, id, contentCount)
  }
});

function getNextContentCount(id: string, highestContentCountMap: Record<string, number>): string {
  if (highestContentCountMap[id] === undefined) {
    highestContentCountMap[id] = 0;
  }
  highestContentCountMap[id] += 1;
  const nextValue = highestContentCountMap[id].toString();
  return nextValue;
}

function mapMetadata(noticeType: NoticeType, noticeTypeKonfiguration: NoticeTypeKonfiguration, noticeSubTypeList: NoticeTypeList): VisualModelNode {
  const visualModelMetadata = {
    contentId: 'notice-metadata',
    contentType: 'notice-metadata',
    contentCount: '1',
    visType: 'non-field',
    children: noticeType.metadata.map(x => {
      const content = {
        contentId: x.id,
        contentType: x.contentType,
        contentCount: '1',
        visType: x.contentType,
        value: ''
      } as VisualModelNode;
      overskrivFeltVaerdi(noticeTypeKonfiguration, x, content, noticeType, noticeSubTypeList);
      return content;
    })
  };

  return visualModelMetadata;
}

function mapContent(
  content: Content[],
  noticeTypeKonfiguration: NoticeTypeKonfiguration,
  highestContenCountMap: Record<string, number>,
  fieldMap: Map<string, Field>,
  noticeType?: NoticeType,
  noticeSubTypeList?: NoticeTypeList,
  aendringsbekendtgoerelse?: AendringsbekendtgoerelseSetting
): VisualModelNode {
  // Mapper rod elementet for data i bekendtgørelsen
  return {
    contentId: 'notice-data',
    contentType: 'notice-data',
    contentCount: '1',
    visType: 'non-field',
    children: mapSubContent(
      content,
      noticeTypeKonfiguration,
      highestContenCountMap,
      true,
      fieldMap,
      noticeType,
      noticeSubTypeList,
      aendringsbekendtgoerelse
    )
  };
}

export function mapSubContent(
  contentList: Content[],
  noticeTypeKonfiguration: NoticeTypeKonfiguration,
  highestContenCountMap: Record<string, number>,
  erLavRepeatable: boolean,
  fieldMap: Map<string, Field>,
  noticeType?: NoticeType,
  noticeSubTypeList?: NoticeTypeList,
  aendringsbekendtgoerelse?: AendringsbekendtgoerelseSetting
): VisualModelNode[] {
  const visualModelContent = [] as VisualModelNode[];

  contentList.forEach(content => {
    if (content.contentType === 'group') {
      // Hvis den er repeatable, lav kun en kopi hvis det ønskes ...
      // Under manuel oprettelse af ny repeatable, undlad at springe over.
      // TODO: Skal vi håndtere dette anderledes?
      if (content._repeatable) {
        console.debug(`mapSubContent _repeatable: ${content.id}`);
        const konfigureretContent = noticeTypeKonfiguration.groups?.find(x => x.id === content.id);
        if (konfigureretContent !== undefined) {
          if (konfigureretContent.opretKunManuelt !== undefined && konfigureretContent.opretKunManuelt && !erLavRepeatable) {
            console.debug('Undlader at lave repeatable for: ' + content.id + ' fordi opretKunManuelt=true');
            return;
          }

          if (!erLavRepeatable) {
            console.debug(`mapSubContent - ${content.id} | konfigureretContent.defaultRepeats => ${konfigureretContent.defaultRepeats}`);
            if (konfigureretContent.defaultRepeats !== undefined && konfigureretContent.defaultRepeats === 0) {
              return;
            }
          }
        }
      }

      const konfigureretContent = noticeTypeKonfiguration.groups?.find(x => x.id === content.id);
      if (konfigureretContent?.removeFromVisualModel !== undefined && konfigureretContent.removeFromVisualModel === true) {
        console.debug(`Undlader at oprette: ${content.id} da den er removeFromVisualModel=true`);
        return;
      }

      if (aendringsbekendtgoerelse !== undefined && aendringsbekendtgoerelse === AendringsbekendtgoerelseSetting.IKKE_AENDRINGSBEKENDTGOERELSE) {
        if (content.id === 'GR-Change') {
          console.debug(`Undlader at oprette: ${content.id} da den er en del af ændringsbekendtgørelse, men dette er ikke en ændringsbekendtgørelse`);
          return;
        }
      }

      // Tilføj kun en knude i visualmodel hvis gruppen har et nodeId.
      if (content.nodeId != null) {
        visualModelContent.push({
          contentId: content.id,
          contentType: content.contentType,
          contentCount: getNextContentCount(content.id, highestContenCountMap),
          children: content.content != null ? mapSubContent(content.content, noticeTypeKonfiguration, highestContenCountMap, false, fieldMap) : [],
          visType: 'non-field',
          visNodeId: content.nodeId
        } as VisualModelNode);
      } else if (content.content) {
        mapSubContent(content.content, noticeTypeKonfiguration, highestContenCountMap, false, fieldMap).forEach(child =>
          visualModelContent.push(child)
        );
      }
    } else if (content.contentType === 'field') {
      const visualModelNode = {
        contentId: content.id,
        contentType: content.contentType,
        contentCount: getNextContentCount(content.id, highestContenCountMap),
        visType: 'field',
        value: ''
      } as VisualModelNode;

      overskrivFeltVaerdi(noticeTypeKonfiguration, content, visualModelNode, noticeType, noticeSubTypeList);
      visualModelContent.push(visualModelNode);

      const field = fieldMap.get(content.id);
      if (field?.attributes !== undefined && field.attributes.length > 0) {
        // Hvis feltet har attributes, lav da en knude til den ved siden af til den.
        for (const attr of field.attributes) {
          const attrField = fieldMap.get(attr);
          if (attrField === undefined) {
            throw new Error('Forventede at attributten var at findes i fields.json, SDK er inkonsistent...');
          }
          // Findes der allerede en attribut for denne?
          if (visualModelContent.find(x => x.contentId == attrField.id && x.contentCount == visualModelNode.contentCount)) {
            continue;
          }

          const override = noticeTypeKonfiguration.fields?.find(x => x.id === attrField.id);

          let value = override?.presetValue ?? attrField.presetValue;

          const isLanguageField = attrField.attributeName === 'languageID';
          if (value === undefined && isLanguageField) {
            const eformsModelStore = useEformsModelStore(window.pinia);
            value = eformsModelStore.currentLanguageId ?? 'DAN';
          }

          if (value == undefined || value === '') {
            // Måske dette felt er destination af en kopierTil?
            const kopierTilSrc = noticeTypeKonfiguration.fields?.find(x => x.kopierTil == attrField.id);
            if (kopierTilSrc !== undefined) {
              value = kopierTilSrc.presetValue ?? '';
              console.log(`🧾 KopierTil fundet som destination: ${attrField.id} får presetValue: ${kopierTilSrc.presetValue}`);
            }
          } else if (value != 'DAN' && value != '' && value != attrField.presetValue) {
            console.log(`🧾 Attribut '${attrField.id}' får presetValue: '${value}' fra NTK`);
          }
          const attributeVisualModelNode = {
            contentId: attrField.id,
            contentType: 'field',
            contentCount: getNextContentCount(attrField.id, highestContenCountMap),
            visType: 'field',
            value: value ?? ''
          } as VisualModelNode;
          visualModelContent.push(attributeVisualModelNode);
        }
      }
    } else {
      console.error(`😡Kender ikke content-type: '${content.contentType}' ???`);
    }
  });

  return visualModelContent;
}

function overskrivFeltVaerdi(
  noticeTypeKonfiguration: NoticeTypeKonfiguration | undefined,
  content: Content | Metadata,
  visualModelNode: VisualModelNode,
  noticeType?: NoticeType,
  noticeSubTypeList?: NoticeTypeList
) {
  const feltIKonfiguration = noticeTypeKonfiguration?.fields?.find(fieldKonfig => fieldKonfig.id === content.id);
  if (feltIKonfiguration?.presetValue != null) {
    console.log(`🧾 Sætter value på '${content.id}' til: '${feltIKonfiguration.presetValue}' - Fra NTK`);
    visualModelNode.value = feltIKonfiguration.presetValue;
    if (feltIKonfiguration.kopierTil !== undefined) {
      console.log('Ville kopiereTil ud fra presetValue');
    }
  } else if (content._presetValue) {
    if (content._presetValue.indexOf('{') === -1) {
      console.log(`🧾 Sætter value på '${content.id}' til: '${content._presetValue}' - Fra SDK`);
      visualModelNode.value = content._presetValue;
    } else {
      console.log(`🔴 Kan ikke håndtere presetValue på '${content.id}: '${content._presetValue}`);
    }
  }

  // Er ID felt, giv auto-id!
  const eformsModelStore = useEformsModelStore(window.pinia);
  const field = eformsModelStore.f.dataMap!.get(content.id)!;
  if (field.type === 'id' && field.idScheme !== undefined) {
    visualModelNode.value = field.idScheme + '-' + eformsModelStore.findNaesteVaerdiMedIdScheme(content);
    console.log(`Sætter automatisk ID på: ${field.id} => '${visualModelNode.value}'`);
  }

  // Defineret i: MUD-849
  // Disse værdier for metadata er lavet i typescript for nu, der er lidt overlap i forhold til hvad der kan gøres med KNT og presetValue.
  if (visualModelNode.contentId === 'OPT-001-notice') {
    visualModelNode.value = noticeType?.ublVersion;
  } else if (visualModelNode.contentId === 'OPT-002-notice') {
    if (noticeType) {
      visualModelNode.value = `${eformsModelStore.sdkVersion}`;
    }
  } else if (visualModelNode.contentId === 'OPP-070-notice') {
    visualModelNode.value = noticeType?.noticeId;
  } else if (visualModelNode.contentId === 'BT-701-notice') {
    visualModelNode.value = eformsUtil.generateUuid();
  } else if (visualModelNode.contentId === 'BT-04-notice') {
    visualModelNode.value = eformsUtil.generateUuid();
  } else if (visualModelNode.contentId === 'BT-03-notice') {
    if (noticeSubTypeList === undefined) {
      throw new Error(`Mangler noticeSubTypeList for ${content.id}`);
    }
    const subtypeDefinition = noticeSubTypeList?.noticeSubTypes.find(subtype => noticeType?.noticeId === subtype.subTypeId);
    visualModelNode.value = subtypeDefinition?.formType;
  } else if (visualModelNode.contentId === 'BT-02-notice') {
    if (noticeSubTypeList === undefined) {
      throw new Error(`Mangler noticeSubTypeList for ${content.id}`);
    }
    const subtypeDefinition = noticeSubTypeList?.noticeSubTypes.find(subtype => noticeType?.noticeId === subtype.subTypeId);
    visualModelNode.value = subtypeDefinition?.type;
  } else if (visualModelNode.contentId === 'BT-757-notice') {
    visualModelNode.value = '01';
  } else if (visualModelNode.contentId === 'BT-01-notice') {
    // Undlad at sætte BT-01-notice, den skal sættes ud fra URL param.
    if (visualModelNode.value !== undefined && visualModelNode.value !== '') {
      console.log('BT-01-notice var allerede sat!');
    }

    // Vær sikker på den aldrig er null / undefined
    if (visualModelNode.value === undefined || visualModelNode.value === null) {
      visualModelNode.value = '';
    }
  } else if (visualModelNode.contentId === 'BT-05(a)-notice') {
    visualModelNode.value = dateUtil.nowDateAsCopenhagen();
  } else if (visualModelNode.contentId === 'BT-05(b)-notice') {
    visualModelNode.value = dateUtil.nowTimeAsCopenhagen();
  } else if (visualModelNode.contentId === 'DKP-012(d)-notice') {
    // Undlad at udfylde disse.
  } else if (visualModelNode.contentId === 'DKP-012(t)-notice') {
    // Undlad at udfylde disse.
  } else if (visualModelNode.contentId === 'BT-803(d)-notice') {
    visualModelNode.value = dateUtil.nowDateAsCopenhagen();
  } else if (visualModelNode.contentId === 'BT-803(t)-notice') {
    visualModelNode.value = dateUtil.nowTimeAsCopenhagen();
  }
}

function initHighestContenCountMap(model: VisualModelNode): Record<string, number> {
  const result: Record<string, number> = {};
  initHighestContenCountMapRec(model, result);
  return result;
}

function initHighestContenCountMapRec(node: VisualModelNode, result: Record<string, number>) {
  if (node.children) {
    for (const child of node.children) {
      const key = `${child.contentId!}`;
      if (result[key] == undefined) {
        result[key] = 1;
      }
      const previousValue = child.contentCount == undefined ? 1 : +child.contentCount;
      result[key] = Math.max(result[key], previousValue);
      if (child.children) {
        initHighestContenCountMapRec(child, result);
      }
    }
  }
}

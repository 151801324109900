import { StateTree, defineStore } from 'pinia';
import { GenericObject } from 'vee-validate';
import { LocationQuery } from 'vue-router';

import { SprogLocale } from '@/main/enums/sprog.enum';
import { Filter, FilterChildTypes } from '@/main/models/filter/filter.model';
import { Publiceringsdato } from '@/main/models/filter/publiceringsdato.model';
import { Tilbudsfrist } from '@/main/models/filter/tilbudfrist.model';
import { FilterDtoFormularTypeItem, PagineringDtoRetning, PagineringDtoSorteringFelt, SoegningQueryDto } from '@/main/models/generated';
import { SoegningQueryDtoUdbudStatusFilter } from '@/main/models/generated/soegningQueryDtoUdbudStatusFilter';
import router from '@/main/router';
import { useEFormsKodelisterStore } from '@/main/stores/eforms-kodelister.state';
import { dateUtil } from '@/main/utils/date-util';
import { filterUtil } from '@/main/utils/filter-util';

import { CodeListFilterKonfigurationNavn, CustomFilterKonfigurationNavn, DatoType, VaerdiType } from '../enums/filter/filterKonfigurationNavn.enum';
import { MainRoute } from '../enums/mainRoute.enum';
import { AnslaaetVaerdi } from '../models/filter/anslaaetvaerdi.model';
import { CPVKoder } from '../models/filter/cpvkoder.model';
import { FormularType } from '../models/filter/formulartype.model';
import { Ordregiver } from '../models/filter/ordregiver.model';
import { SMVVenlighedsType } from '../models/filter/smvvenlighed.model';
import { TypeAfOpgave } from '../models/filter/typeafopgave.model';
import { TypeAfProcedure } from '../models/filter/typeafprocedure.model';
import { bekendtgoerelseService } from '../services/bekendtgoerelse.service';

interface SoegningValg {
  soegning: SoegningQueryDto;
  filterItems: Filter[];
  validationError: boolean;
  totalCount: number;
  gemtSoegningNavn: string;
  itemClicked: boolean;
  cpvValg: {
    positivListe: string[];
    negativListe: string[];
  };
}

type StoredSoegningValg = Pick<SoegningValg, 'soegning' | 'filterItems' | 'validationError' | 'totalCount'>;

const defaultValue = {
  soegning: {
    fritekstQuery: undefined,
    pagineringDto: {
      aktuelSide: 1,
      maksElementer: 25,
      sorteringFelt: PagineringDtoSorteringFelt.RELEVANCE,
      retning: PagineringDtoRetning.Desc
    },
    filterDto: {
      formularType: [],
      opgaveType: [],
      procedureType: [],
      smvVenligType: []
    },
    udbudStatusFilter: SoegningQueryDtoUdbudStatusFilter.AKTIV
  },
  filterItems: [
    {
      titel: 'ordregiver.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_ORDREGIVER,
      filterChild: {
        Ordregiver: []
      }
    },
    {
      titel: 'cpv-koder.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_CPV_KODER,
      filterChild: {
        CPVListeItem: [],
        positivListe: [],
        negativListe: []
      }
    },
    {
      titel: 'formulartype.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE,
      current: false,
      active: false,
      filterChild: {
        formularType: []
      }
    },
    {
      titel: 'type-af-opgave.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE,
      current: false,
      active: false,
      filterChild: {
        opgaveType: []
      }
    },
    {
      titel: 'publiceringsdato.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO,
      filterChild: {
        publikationDatoFra: '',
        publikationDatoTil: ''
      }
    },
    {
      titel: 'tilbudsfrist.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST,
      filterChild: {
        tilbudsfristDatoFra: '',
        tilbudsfristDatoTil: ''
      }
    },
    {
      titel: 'SMV-venlighed.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED,
      current: false,
      active: false,
      filterChild: {
        smvVenligType: []
      }
    },
    {
      titel: 'type-af-procedure.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE,
      current: false,
      active: false,
      filterChild: {
        procedureType: []
      }
    },
    {
      titel: 'anslaaet-vaerdi.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI,
      filterChild: {
        anslaaetVaerdiFra: '',
        anslaaetVaerdiTil: '',
        anslaaetVaerdiValuta: ''
      }
    }
  ],
  validationError: false,
  totalCount: 0,
  gemtSoegningNavn: '',
  itemClicked: false,
  cpvValg: {
    positivListe: [],
    negativListe: []
  }
} as SoegningValg;

export const useSoegningValgStore = defineStore('SoegningValg', {
  state: () => {
    const restoredState = localStorage.getItem('soegningValgStore');
    const defaultValueClone = structuredClone(defaultValue);
    if (restoredState) {
      const parsedState: StoredSoegningValg = JSON.parse(restoredState);
      const mergedFilterItems = filterUtil.mergeFilterItems(defaultValueClone.filterItems, parsedState.filterItems);
      return {
        ...defaultValueClone,
        ...parsedState,
        filterItems: mergedFilterItems
      };
    }
    return defaultValueClone;
  },
  persist: {
    serializer: {
      serialize: (value: StateTree) => {
        const { soegning, filterItems, totalCount } = value as SoegningValg;
        return JSON.stringify({
          soegning,
          filterItems,
          totalCount
        } as StoredSoegningValg);
      },
      deserialize: (value: string) => {
        const restored: StoredSoegningValg = JSON.parse(value);
        const defaultValueClone = structuredClone(defaultValue);
        const mergedFilterItems = filterUtil.mergeFilterItems(defaultValueClone.filterItems, restored.filterItems);
        return {
          ...restored,
          filterItems: mergedFilterItems
        } as SoegningValg;
      }
    }
  },
  actions: {
    setAktiveAlle() {
      if (this.soegning.udbudStatusFilter === SoegningQueryDtoUdbudStatusFilter.ALLE) {
        this.soegning.udbudStatusFilter = SoegningQueryDtoUdbudStatusFilter.AKTIV;
      } else if (this.soegning.udbudStatusFilter === SoegningQueryDtoUdbudStatusFilter.AKTIV) {
        this.soegning.udbudStatusFilter = SoegningQueryDtoUdbudStatusFilter.ALLE;
      }
    },
    currentFilterCleared(value: boolean): boolean {
      return (this.itemClicked = value);
    },
    // Additional action you might need
    resetCurrentFilterCleared(): boolean {
      return (this.itemClicked = false);
    },
    async fetchKoebersRolledata() {
      try {
        const response = await bekendtgoerelseService.hentAlleKoebersRolledata();
        return response;
      } catch (error) {
        console.error('Fetch error:', error);
        throw error;
      }
    },
    updateSoegstate(payload: SoegningQueryDto) {
      this.soegning = payload;
    },
    setGemtSoegningNavn(value: string) {
      this.gemtSoegningNavn = value;
    },
    resetFritekstQuery() {
      if (this.soegning) {
        this.soegning.fritekstQuery = undefined;
      }
    },
    resetSortering() {
      if (this.soegning.pagineringDto) {
        this.soegning.pagineringDto.sorteringFelt = PagineringDtoSorteringFelt.PUBLIKATION_DATO;
        this.soegning.pagineringDto.retning = PagineringDtoRetning.Desc;
        this.soegning.pagineringDto.aktuelSide = 1;
      }
    },
    async updateFromRoute(query: LocationQuery) {
      if (query.soegeTekst !== undefined) {
        this.soegning.fritekstQuery = decodeURI(query.soegeTekst as string);
      } else {
        this.soegning.fritekstQuery = undefined;
      }
      if (this.soegning.pagineringDto === undefined) {
        this.soegning.pagineringDto = {};
      }
      this.soegning.pagineringDto.maksElementer = filterUtil.setOrDefault(parseInt(query.maksElementer as string, 10), 25);
      this.soegning.pagineringDto.aktuelSide = filterUtil.setOrDefault(parseInt(query.aktuelSide as string), 1);
      this.soegning.pagineringDto.sorteringFelt = filterUtil.setOrDefault(
        query.sorteringFelt as PagineringDtoSorteringFelt,
        PagineringDtoSorteringFelt.PUBLIKATION_DATO
      );
      this.soegning.udbudStatusFilter = filterUtil.setOrDefault(
        query.toggleAktivAlle as SoegningQueryDtoUdbudStatusFilter,
        SoegningQueryDtoUdbudStatusFilter.AKTIV
      );
      this.soegning.pagineringDto.retning = filterUtil.setOrDefault(query.sorteringRetning as PagineringDtoRetning, PagineringDtoRetning.Desc);
      if (this.soegning.filterDto === undefined) {
        this.soegning.filterDto = {};
      }
      filterUtil.resetActiveAndCurrentState();
      filterUtil.clearAllFilter(false);
      this.setPublikationDatoFra(query.publikationDatoFra as string);
      this.setPublikationDatoTil(query.publikationDatoTil as string);
      this.setTilbudsfristFra(query.tilbudsfristDatoFra as string);
      this.setTilbudsfristTil(query.tilbudsfristDatoTil as string);
      this.setAnslaaetVaerdiFra(query.anslaaetVaerdiFra as string);
      this.setAnslaaetVaerdiTil(query.anslaaetVaerdiTil as string);
      this.setAnslaaetVaerdiValuta(query.anslaaetVaerdiValuta as string);
      this.setOrdregiver(query.koeberCvr as string[]);
      await this.setCPVKoderFromQuery(query.cpvPos as string, query.cpvNeg as string);
      this.setFormularType(query.formularType as string[]);
      this.setSmvVenligType(query.smvVenligType as string[]);
      this.setOpgaveType(query.opgaveType as string[]);
      this.setProcedureType(query.procedureType as string[]);
    },
    setPublikationDatoFra(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
      (filter?.filterChild as Publiceringsdato).publikationDatoFra = formateret;
      this.soegning.filterDto!.publikationDatoFra = formateret;
    },
    setPublikationDatoTil(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
      (filter?.filterChild as Publiceringsdato).publikationDatoTil = formateret;
      this.soegning.filterDto!.publikationDatoTil = formateret;
    },
    setTilbudsfristFra(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
      (filter?.filterChild as Tilbudsfrist).tilbudsfristDatoFra = formateret;
      this.soegning.filterDto!.tilbudsfristDatoFra = formateret;
    },
    setTilbudsfristTil(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
      (filter?.filterChild as Tilbudsfrist).tilbudsfristDatoTil = formateret;
      this.soegning.filterDto!.tilbudsfristDatoTil = formateret;
    },

    setAnslaaetVaerdiFra(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      } else {
        const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        (filter?.filterChild as AnslaaetVaerdi).anslaaetVaerdiFra = indhold;
        this.soegning.filterDto!.anslaaetVaerdiFra = indhold;
      }
    },

    setAnslaaetVaerdiTil(indhold: string | undefined) {
      if (indhold === undefined || indhold === null) {
        return;
      } else {
        const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        (filter?.filterChild as AnslaaetVaerdi).anslaaetVaerdiTil = indhold;
        this.soegning.filterDto!.anslaaetVaerdiTil = indhold;
      }
    },

    setAnslaaetVaerdiValuta(indhold: string | undefined) {
      if (indhold === undefined || indhold === null) {
        return;
      }
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
      (filter?.filterChild as AnslaaetVaerdi).anslaaetVaerdiValuta = indhold;
      this.soegning.filterDto!.anslaaetVaerdiValuta = indhold;
    },

    setOrdregiver(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ORDREGIVER);
      if (filter && filter.filterChild) {
        (filter.filterChild as Ordregiver).koeberCvr = indhold;
        this.soegning.filterDto!.koeberCvr = indhold;
      } else {
        console.error('Filter not found or filterChild is undefined');
      }
    },
    async setCPVKoderFromQuery(cpvPos: string, cpvNeg: string) {
      if (cpvPos === undefined) {
        return;
      }
      let cpvPositivListe: string[] = [];
      if (typeof cpvPos === 'string') {
        cpvPositivListe = cpvPos.split(',').map(item => item.trim().padEnd(8, '0'));
      }
      let cpvNegativListe: string[] = [];
      if (typeof cpvNeg === 'string') {
        cpvNegativListe = cpvNeg.split(',').map(item => item.trim().padEnd(8, '0'));
      }

      this.cpvValg.positivListe = cpvPositivListe;
      this.cpvValg.negativListe = cpvNegativListe;

      if (cpvPositivListe.length > 0) {
        // Sæt CPV koder
        const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_CPV_KODER);
        if (filter && filter.filterChild) {
          const alleCpvKoder = await filterUtil.getAlleCPVKoder();
          const cpvKoderListe = filterUtil.beregnOptimaleCpvKoder(this.cpvValg.positivListe, this.cpvValg.negativListe, alleCpvKoder);
          (filter.filterChild as CPVKoder).cpvKoder = cpvKoderListe;
        }
      }
    },
    async addCPVKode(cpvKode: string, nested: boolean) {
      const prefix = filterUtil.getCpvPrefix(cpvKode);
      const varPaaNegativListe = this.cpvValg.negativListe.findIndex(x => x == cpvKode) !== -1;
      const boernPaaNegativListe = this.cpvValg.negativListe.some(x => x.startsWith(prefix));

      if (nested) {
        this.cpvValg.negativListe = this.cpvValg.negativListe.filter(x => !x.startsWith(prefix));
        // Behold nuværende CPV kode
        this.cpvValg.positivListe = this.cpvValg.positivListe.filter(x => !x.startsWith(prefix) || x == cpvKode);

        // Undlad at gøre mere, den er ændret nu.
        if (!varPaaNegativListe) {
          const parentsPaaPositivListe = this.cpvValg.positivListe.filter(x => cpvKode.startsWith(filterUtil.getCpvPrefix(x)));
          const parentsPaaNegativListe = this.cpvValg.negativListe.filter(x => cpvKode.startsWith(filterUtil.getCpvPrefix(x)));

          if (parentsPaaPositivListe.length > 0 && parentsPaaNegativListe.length == 0) {
            // Denne er allerede inkluderet nu, undlad at gøre noget ..
          } else if (!this.cpvValg.positivListe.some(x => x == cpvKode)) {
            this.cpvValg.positivListe.push(cpvKode);
          } else if (!boernPaaNegativListe) {
            this.cpvValg.positivListe.push(cpvKode);
          }
        }
      } else {
        // Problem: Hvis denne tidligere var på negativ-liste, så bliver den kun tilføjes til positivlisten, og ikke fjernet fra negativlisten.

        const alleCpvKoder = await filterUtil.getAlleCPVKoder();
        const previouslyOnNegativeMinusCurrent = this.cpvValg.negativListe.filter(x => x != cpvKode);
        const childCodes = alleCpvKoder
          .filter(x => x.value.startsWith(prefix) && filterUtil.getCpvPrefix(x.value).length == prefix.length + 1)
          .filter(x => !this.cpvValg.positivListe.some(y => y == x.value));

        this.cpvValg.negativListe = [...previouslyOnNegativeMinusCurrent, ...childCodes.map(x => x.value)].sort((a, b) => a.localeCompare(b));

        if (!this.erCpvKodeAktiv(cpvKode)) {
          // Tilføj denne ene til listen, hvis ikke allerede der var aktiv pga. ændringer til negativliste:
          this.cpvValg.positivListe.push(cpvKode);
        }
      }

      const eformsKodelisterStore = useEFormsKodelisterStore(window.pinia);
      const CPVKodelisteData = await eformsKodelisterStore.hentKodelisteVaerdierMaaskeFraCache(SprogLocale.Dansk, 'cpv');
      const cpvKodelisteVaerdierList = CPVKodelisteData.map(x => ({
        value: x.codeValue,
        label: x.da! || x.en!
      }));
      const alleAktive = filterUtil.beregnAktiveCpvKoder(this.cpvValg.positivListe, this.cpvValg.negativListe, cpvKodelisteVaerdierList);
      const erAktiv = alleAktive.some(x => x.value == cpvKode);
      if (!erAktiv) {
        console.error('Dårligt state, denne cpvKode er netop tilvalgt og er ikke aktiv!: ' + cpvKode);
      }
    },
    async removeCPVKode(cpvKode: string, nested: boolean) {
      const prefix = filterUtil.getCpvPrefix(cpvKode);
      const varPaaPositivListe = this.cpvValg.positivListe.findIndex(x => x == cpvKode) !== -1;

      if (nested) {
        this.cpvValg.positivListe = this.cpvValg.positivListe.filter(x => !x.startsWith(prefix));
        this.cpvValg.negativListe = this.cpvValg.negativListe.filter(x => !x.startsWith(prefix));

        // Hvis denne var explicit tilføjet, skal den IKKE tilføjet til negativListe for at blive omvendt.
        // Det er nok at fjerne den fra positivlisten
        if (!varPaaPositivListe) {
          this.cpvValg.negativListe.push(cpvKode);
        } else {
          // Beregn om den nuværnede cpv-kode er aktiv eller ej, den er aktiv hvis det længe prefix af den er på positivlisten fremfor negativlisten.
          const erAktivLigeNu = this.erCpvKodeAktiv(cpvKode);
          if (erAktivLigeNu) {
            this.cpvValg.negativListe.push(cpvKode);
          }
        }
      } else if (varPaaPositivListe) {
        const alleCpvKoder = await filterUtil.getAlleCPVKoder();
        // Find børn som var aktive før og derfor stadig skal være (nested=false)
        const childCodes = alleCpvKoder
          .filter(x => x.value.startsWith(prefix) && filterUtil.getCpvPrefix(x.value).length == prefix.length + 1)
          .filter(x => !this.cpvValg.negativListe.some(y => y == x.value));

        // Dette er casen hvor den tidligere var tilvalgt uden børn:
        this.cpvValg.positivListe = [...this.cpvValg.positivListe.filter(x => x != cpvKode), ...childCodes.map(x => x.value)];

        // Var der ting på negativlisten som ikke giver mening nu?
        const nyNegativ = this.cpvValg.negativListe.filter(x => {
          const erNegativAf = this.cpvValg.positivListe.filter(pos => x.startsWith(filterUtil.getCpvPrefix(pos)));
          return erNegativAf.length > 0;
        });
        this.cpvValg.negativListe = nyNegativ;
      } else {
        // Denne var ikke allerede tilvalgt, men kun med pga. en forældre var inkluderet.
        const alleCpvKoder = await filterUtil.getAlleCPVKoder();
        const childCodes = alleCpvKoder.filter(x => x.value.startsWith(prefix) && filterUtil.getCpvPrefix(x.value).length == prefix.length + 1);
        const aktive = childCodes.filter(x => this.erCpvKodeAktiv(x.value));
        this.cpvValg.positivListe.push(...aktive.map(x => x.value));

        const toRemove = this.cpvValg.negativListe.filter(x => filterUtil.getCpvPrefix(x).startsWith(prefix));
        this.cpvValg.negativListe.push(cpvKode);
        this.cpvValg.negativListe = this.cpvValg.negativListe.filter(x => !toRemove.some(y => x == y));
      }

      const eformsKodelisterStore = useEFormsKodelisterStore(window.pinia);
      const CPVKodelisteData = await eformsKodelisterStore.hentKodelisteVaerdierMaaskeFraCache(SprogLocale.Dansk, 'cpv');
      const cpvKodelisteVaerdierList = CPVKodelisteData.map(x => ({
        value: x.codeValue,
        label: x.da! || x.en!
      }));
      const alleAktive = filterUtil.beregnAktiveCpvKoder(this.cpvValg.positivListe, this.cpvValg.negativListe, cpvKodelisteVaerdierList);
      const erAktiv = alleAktive.some(x => x.value == cpvKode);
      if (erAktiv) {
        console.error('Dårligt state, denne cpvKode er netop fravalgt og er aktiv stadig!: ' + cpvKode);
      }
    },
    erCpvKodeAktiv(cpvKode: string) {
      const pl = this.cpvValg.positivListe.filter(x => cpvKode.startsWith(filterUtil.getCpvPrefix(x)));
      const nl = this.cpvValg.negativListe.filter(x => cpvKode.startsWith(filterUtil.getCpvPrefix(x)));

      const posLength = Math.max(...pl.map(x => filterUtil.getCpvPrefix(x).length));
      const negLength = Math.max(...nl.map(x => filterUtil.getCpvPrefix(x).length));
      const erAktivLigeNu = posLength > negLength;
      return erAktivLigeNu;
    },
    setFormularType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE);
      (filter?.filterChild as FormularType).formularType = indhold;
      this.soegning.filterDto!.formularType = indhold;
    },
    setSmvVenligType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED);
      (filter?.filterChild as SMVVenlighedsType).smvVenligType = indhold;
      this.soegning.filterDto!.smvVenligType = indhold;
    },
    setOpgaveType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE);
      (filter?.filterChild as TypeAfOpgave).opgaveType = indhold;
      this.soegning.filterDto!.opgaveType = indhold;
    },
    setProcedureType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE);
      (filter?.filterChild as TypeAfProcedure).procedureType = indhold;
      this.soegning.filterDto!.procedureType = indhold;
    },
    updateRoute() {
      const fritekstQuery = this.soegning.fritekstQuery ? { soegeTekst: encodeURI(this.soegning.fritekstQuery) } : {};
      // Undgå at ændringer til filterDto foretager ændringer til storen (og derfor watches)
      const filterChildParamsCopy = JSON.parse(JSON.stringify(this.soegning.filterDto));

      if (filterChildParamsCopy == undefined) {
        throw new Error('ingen parametre?');
      }

      if (filterChildParamsCopy?.koeberCvr?.length === 0) {
        filterChildParamsCopy.koeberCvr = undefined;
      }
      if (filterChildParamsCopy?.publikationDatoFra === '') {
        filterChildParamsCopy.publikationDatoFra = undefined;
      }
      if (filterChildParamsCopy?.publikationDatoTil === '') {
        filterChildParamsCopy.publikationDatoTil = undefined;
      }
      if (filterChildParamsCopy?.tilbudsfristDatoFra === '') {
        filterChildParamsCopy.tilbudsfristDatoFra = undefined;
      }
      if (filterChildParamsCopy?.tilbudsfristDatoTil === '') {
        filterChildParamsCopy.tilbudsfristDatoTil = undefined;
      }
      if (filterChildParamsCopy?.anslaaetVaerdiFra === '') {
        filterChildParamsCopy.anslaaetVaerdiFra = undefined;
      }
      if (filterChildParamsCopy?.anslaaetVaerdiTil === '') {
        filterChildParamsCopy.anslaaetVaerdiTil = undefined;
      }
      if (filterChildParamsCopy?.anslaaetVaerdiValuta === '') {
        filterChildParamsCopy.anslaaetVaerdiValuta = undefined;
      }

      const cpvPos = this.cpvValg.positivListe.map(code => code.replace(/0+$/, ''));
      const cpvPosKoderString = cpvPos?.join(',') || undefined;

      const cpvNeg = this.cpvValg.negativListe.map(code => code.replace(/0+$/, ''));
      const cpvNegKoderString = cpvNeg?.join(',') || undefined;

      if (filterChildParamsCopy.cpvKoder !== undefined) {
        filterChildParamsCopy.cpvKoder = undefined;
      }

      router.push({
        name: MainRoute.SOEGNING,
        query: {
          aktuelSide: this.soegning.pagineringDto?.aktuelSide,
          maksElementer: this.soegning.pagineringDto?.maksElementer,
          sorteringFelt: this.soegning.pagineringDto?.sorteringFelt,
          sorteringRetning: this.soegning.pagineringDto?.retning,
          toggleAktivAlle: this.soegning.udbudStatusFilter || SoegningQueryDtoUdbudStatusFilter.AKTIV,

          ...fritekstQuery,
          ...filterChildParamsCopy,
          cpvPos: cpvPosKoderString,
          cpvNeg: cpvNegKoderString
        }
      });
    },
    async searchForOnlyCpv(cpv: string) {
      // Nulstil søgningen
      const defaultValueClone = structuredClone(defaultValue);
      this.soegning = {
        fritekstQuery: '',
        ...defaultValueClone.soegning
      };
      // Reset CPV filter
      this.cpvValg = {
        positivListe: [],
        negativListe: []
      };

      // search for code without children
      await this.addCPVKode(cpv, false);

      // Execute a search ...
      await filterUtil.searchWithFilter(true);
    },
    search(data?: GenericObject) {
      if (data !== undefined) {
        this.soegning.fritekstQuery = data.values['search-query'];
        this.soegning.pagineringDto!.aktuelSide = 1;
      }
    },
    getFilter: function (navn: CodeListFilterKonfigurationNavn | CustomFilterKonfigurationNavn) {
      return this.filterItems.find(item => item.konfigurationNavn === navn);
    },
    updateCodelistToCheckboxFilter(codelistFilterNavn: CodeListFilterKonfigurationNavn | CustomFilterKonfigurationNavn, values: string[]) {
      const filter = this.getFilter(codelistFilterNavn);
      const filterChild = filter?.filterChild!;
      switch (codelistFilterNavn) {
        case CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE:
          (filterChild as FormularType).formularType = values as FilterDtoFormularTypeItem[];
          break;
        case CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED:
          (filterChild as SMVVenlighedsType).smvVenligType = values;
          break;
        case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE:
          (filterChild as TypeAfOpgave).opgaveType = values;
          break;
        case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE:
          (filterChild as TypeAfProcedure).procedureType = values;
          break;
        case CustomFilterKonfigurationNavn.FILTER_ORDREGIVER:
          (filterChild as Ordregiver).koeberCvr = values;
          break;
      }
      if (filter) {
        filter.filterChild = filterChild;
      }
    },
    setValidationError(state: boolean) {
      this.validationError = state;
    },
    async updateTotalCount() {
      let totalCount = 0;
      for (const item of this.filterItems) {
        if (item.titel === 'cpv-koder.button.titel') {
          const alleCpvKoder = await filterUtil.getAlleCPVKoder();
          totalCount += filterUtil.beregnAktiveCpvKoder(this.cpvValg.positivListe, this.cpvValg.negativListe, alleCpvKoder).length;
        } else {
          const filterChild = item.filterChild as FilterChildTypes | undefined;
          if (filterChild) {
            Object.values(filterChild).forEach(value => {
              if (Array.isArray(value)) {
                totalCount += value.filter(val => typeof val === 'string' && val.trim() !== '').length;
              } else if (typeof value === 'string' && value.trim() !== '') {
                totalCount++;
              }
            });
          }
        }
      }
      this.totalCount = totalCount;
    }
  },
  getters: {
    getSelectedValues() {
      return (konfigurationNavn: CodeListFilterKonfigurationNavn) => {
        const filter = this.filterItems.find(item => item.konfigurationNavn === konfigurationNavn);
        if (filter) {
          switch (konfigurationNavn) {
            case CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE:
              return (filter.filterChild as FormularType)?.formularType ?? [];
            case CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED:
              return (filter.filterChild as SMVVenlighedsType)?.smvVenligType ?? [];
            case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE:
              return (filter.filterChild as TypeAfOpgave)?.opgaveType ?? [];
            case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE:
              return (filter.filterChild as TypeAfProcedure)?.procedureType ?? [];
            default:
              return [];
          }
        } else {
          return [];
        }
      };
    },
    getSelectedCustomValue() {
      return (konfigurationNavn: CustomFilterKonfigurationNavn, type?: DatoType | VaerdiType) => {
        const filter = this.filterItems.find(item => item.konfigurationNavn === konfigurationNavn);
        if (!filter) {
          return undefined;
        }

        switch (konfigurationNavn) {
          case CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO:
            if (type === DatoType.DATO_FRA) {
              console.log(
                '(filter.filterChild as Publiceringsdato)?.publikationDatoFra',
                (filter.filterChild as Publiceringsdato)?.publikationDatoFra
              );

              return (filter.filterChild as Publiceringsdato)?.publikationDatoFra ?? undefined;
            }
            return (filter.filterChild as Publiceringsdato)?.publikationDatoTil ?? undefined;

          case CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST:
            if (type === DatoType.DATO_FRA) {
              return (filter.filterChild as Tilbudsfrist)?.tilbudsfristDatoFra ?? undefined;
            }
            return (filter.filterChild as Tilbudsfrist)?.tilbudsfristDatoTil ?? undefined;

          case CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI:
            if (type === VaerdiType.ANSLAAET_VAERDI_FRA) {
              return (filter.filterChild as AnslaaetVaerdi)?.anslaaetVaerdiFra ?? undefined;
            } else if (type === VaerdiType.ANSLAAET_VAERDI_TIL) {
              return (filter.filterChild as AnslaaetVaerdi)?.anslaaetVaerdiTil ?? undefined;
            } else {
              return (filter.filterChild as AnslaaetVaerdi)?.anslaaetVaerdiValuta ?? undefined;
            }

          case CustomFilterKonfigurationNavn.FILTER_ORDREGIVER:
            return (filter.filterChild as Ordregiver)?.koeberCvr ?? [];

          case CustomFilterKonfigurationNavn.FILTER_CPV_KODER:
            return (filter.filterChild as CPVKoder)?.cpvKoder ?? [];

          default:
            return undefined;
        }
      };
    }
  }
});

import { KonfigureretContent } from '@/main/models/eforms/KonfigureretContent';
import { KonfigureretMetadata } from '@/main/models/eforms/KonfigureretMetadata';
import { KonfigureretNoticeType } from '@/main/models/eforms/KonfigureretNoticeType';
import { AendringsbekendtgoerelseSetting } from '@/main/models/eforms/aendringsbekendtgoerelseSetting';
import { Content, Field, FieldKonfiguration, GroupKonfiguration, NoticeType, NoticeTypeKonfiguration } from '@/main/models/generated';

const INDEX_INCREMENT = 100;

const ROOT_NODE_NAME = 'ND-Root';

export class EFormsFormularKonfigurationService {
  konfigurerNoticeType(
    noticeType: NoticeType,
    konfig: NoticeTypeKonfiguration,
    fieldMap: Map<string, Field>,
    aendringsbekendtgoerelseSetting: AendringsbekendtgoerelseSetting
  ): KonfigureretNoticeType {
    const knt = this.deepCopyNoticeType(noticeType);
    // Lav et falsk root element, så resterende algoritmer kan virke med den som et element.
    const fakeRoot: KonfigureretContent = this.lavFakeRoot(knt);

    // Hvis vi ikke er i en ændringsbekentgørelse skal vi skjule GR-Change
    const groupsToBeRemoved: GroupKonfiguration[] =
      aendringsbekendtgoerelseSetting == AendringsbekendtgoerelseSetting.IKKE_AENDRINGSBEKENDTGOERELSE ? [{ id: 'GR-Change' }] : [];
    // Anvend regler fra config på grupper
    konfig.groups?.forEach(override => {
      if (this.skalBehandles(override, noticeType)) {
        this.flytEllerOpretGruppe(knt, override, fakeRoot, groupsToBeRemoved);
      }
    });

    // Anvend regler fra config på felter
    konfig.fields?.forEach(override => {
      if (!this.skalBehandles(override, noticeType)) {
        return;
      }

      // Felter skal findes i den givne notice type. Hvis de ikke findes deri, så undlad at forsøge at behandle dem.
      const knudeIKnt = this.findKnude(knt.content, override.id);
      if (knudeIKnt === undefined) {
        // Blev ikke fundet som en knude i felter, måske er det metadata?
        const knudeSomMetadata = this.findMetadata(knt, override.id);

        if (knudeSomMetadata === undefined) {
          if (fieldMap.get(override.id)?.attributeOf !== undefined) {
            // Nogle felter er attibutter og findes derfor ikke i notice-type, men skal stadig konfigureres (f.eks. preset-value).
            // Vi undlader at modificere KonfigurertContent med deres ændringer.
            return;
          }
          console.error(`Knude: '${override.id}' findes ikke i denne type bekendtgørelse. Bør tilføjes til whitelist? Spriger over ...`);
          return;
        }

        // Opret nyt felt ud fra metadata ...
        const metadataSomFelt = this.lavFeltUdfraMetadata(override, knudeSomMetadata);

        if (metadataSomFelt.hidden === true && (metadataSomFelt.hiddenFromReceipt === false || metadataSomFelt.hiddenFromSummary === false)) {
          console.warn(`💻metadata-felt: '${metadataSomFelt.id}' er hidden. Men skal vises på opsummering/kvittering, så vi fjerner den ikke.`);
          this.insertNodeInParent(knt, override, metadataSomFelt, fakeRoot);
        } else if (metadataSomFelt.hidden) {
          console.log(`💻metadata-felt: '${metadataSomFelt.id}' er hidden. Undlader at tilføje til konfig`);
        } else {
          // Indsæt felt i gruppen, så det renderes.
          this.insertNodeInParent(knt, override, metadataSomFelt, fakeRoot);
        }
        return;
      }

      this.flytFelt(knudeIKnt, override, knt, fakeRoot);
    });

    // Fjern "skjulte" grupper. Dette skal ske efter felter har fået en chance for at blive flyttet ud.
    groupsToBeRemoved.forEach(override => {
      this.fjernGruppe(knt, override);
    });

    return knt;
  }

  private lavFakeRoot(knt: KonfigureretNoticeType): KonfigureretContent {
    return {
      id: ROOT_NODE_NAME,
      index: 0,
      contentType: 'group',
      displayType: 'GROUP',
      description: '',
      _label: '',
      content: knt.content, // Reference til samme array som i KNT
      parentId: 'dummy',
      visualModelParentId: ROOT_NODE_NAME
    };
  }

  private skalBehandles(override: GroupKonfiguration | FieldKonfiguration, noticeType: NoticeType): boolean {
    // id feltet skal altid være angivet, ellers er konfigurationen ugyldig, kast fejl.
    if (!override.id) {
      throw new Error('Mangler "id" attribut på NoticeTypeKonfiguration element: ' + JSON.stringify(override));
    }

    // Tjek om element er på whitelist hvis den findes.
    if (override.noticeTypeWhitelist !== undefined && !override.noticeTypeWhitelist?.some(x => x === noticeType.noticeId)) {
      // console.debug(
      //   `Notice type: '${noticeType.noticeId}' er ikke i whitelist for element '${override.id}': ${JSON.stringify(
      //     override.noticeTypeWhitelist
      //   )} ... springer over`
      // );
      return false;
    }

    return true;
  }

  private fjernGruppe(knt: KonfigureretNoticeType, override: GroupKonfiguration) {
    const knudeIKnt = this.findKnude(knt.content, override.id);
    if (knudeIKnt != null) {
      this.removeFromOldParent(knt, knudeIKnt);
    }
  }

  private flytEllerOpretGruppe(
    knt: KonfigureretNoticeType,
    override: GroupKonfiguration,
    fakeRoot: KonfigureretContent,
    groupsToBeRemoved: GroupKonfiguration[]
  ) {
    const knudeIKnt: KonfigureretContent | undefined = this.findKnude(knt.content, override.id);
    if (knudeIKnt != undefined) {
      // Denne gruppe/section findes allerede. Overskriv værdier
      knudeIKnt.index = override.index ?? knudeIKnt.index;
      knudeIKnt.description = override.description ?? knudeIKnt.description;
      knudeIKnt._label = override._label ?? knudeIKnt._label;
      knudeIKnt.forceVisOverskrift = override.forceVisOverskrift;
      knudeIKnt.defaultRepeats = override.defaultRepeats ?? 0;
      knudeIKnt.minRepeats = override.minRepeats;
      knudeIKnt._repeatable = override._repeatable ?? knudeIKnt._repeatable;
      knudeIKnt.oprindeligRepeatable = knudeIKnt.oprindeligRepeatable ?? knudeIKnt._repeatable; // Undlad at overskrive denne med KNT data.
      knudeIKnt.flyttesPaaTvaersAfXml = override.flyttesPaaTvaersAfXml ?? knudeIKnt.flyttesPaaTvaersAfXml;
      knudeIKnt.opretKunManuelt = override.opretKunManuelt ?? knudeIKnt.opretKunManuelt;
      knudeIKnt.displayType = override.displayType ?? knudeIKnt.displayType;
      knudeIKnt.contentType = override.contentType ?? knudeIKnt.contentType;
      knudeIKnt.component = override.component ?? knudeIKnt.component;
      knudeIKnt.hiddenFromReceipt = override.hiddenFromReceipt ?? knudeIKnt.hiddenFromReceipt;
      knudeIKnt.hiddenFromSummary = override.hiddenFromSummary ?? knudeIKnt.hiddenFromSummary;
      knudeIKnt.choiceGroupConfiguration = override.choiceGroupConfiguration ?? knudeIKnt.choiceGroupConfiguration;

      if (override.hidden !== undefined && override.hidden) {
        // Vi kan ikke fjerne gruppen nu, da den stadig kan indeholde felter vi skal bruge senere.
        groupsToBeRemoved.push(override);
      } else if (override.parent === undefined) {
        // Flyt inde for samme parent
        this.moveNodeInParent(knt, knudeIKnt.parentId, knudeIKnt, fakeRoot);
      } else if (knudeIKnt.parentId !== override.parent) {
        // Hvis det er ny forældre, flyt da den.
        this.insertNodeInParent(knt, override, knudeIKnt, fakeRoot);
        this.removeFromOldParent(knt, knudeIKnt);
      } else {
        // Flyt placering i liste:
        this.moveNodeInParent(knt, override.parent, knudeIKnt, fakeRoot);
      }
    } else {
      if (override.parent === undefined) {
        console.error(`Ville lave ny gruppe: '${override.id}', men der ikke angivet nogen parent - fejl!`);
        return;
      }
      const newParent = this.findParentOrFakeRoot(override.parent, fakeRoot, knt);

      // Ny gruppe, opret den:
      const group: KonfigureretContent = {
        id: override.id,
        _label: override._label!,
        contentType: override.contentType!,
        displayType: override.displayType!,
        description: override.description ?? '',
        index: override.index ?? 0,
        nodeId: override.nodeId,
        _repeatable: override._repeatable,
        parentId: newParent?.id,
        visualModelParentId: newParent.visualModelParentId,
        forceVisOverskrift: override.forceVisOverskrift,
        defaultRepeats: override.defaultRepeats ?? 0,
        component: override.component,
        minRepeats: override.minRepeats ?? 0,
        flyttesPaaTvaersAfXml: override.flyttesPaaTvaersAfXml,
        opretKunManuelt: override.opretKunManuelt,
        hiddenFromReceipt: override.hiddenFromReceipt,
        hiddenFromSummary: override.hiddenFromSummary,
        choiceGroupConfiguration: override.choiceGroupConfiguration
      };

      this.insertNodeInParent(knt, override, group, fakeRoot);
    }
  }

  private flytFelt(knudeIKnt: KonfigureretContent, override: FieldKonfiguration, knt: KonfigureretNoticeType, fakeRoot: KonfigureretContent) {
    knudeIKnt._label = override._label ?? knudeIKnt._label;
    knudeIKnt.displayType = override.displayType ?? knudeIKnt.displayType;
    knudeIKnt.description = override.description ?? knudeIKnt.description;
    knudeIKnt.index = override.index ?? knudeIKnt.index;
    knudeIKnt.codeListFilter = override.codeListFilter;
    knudeIKnt.component = override.component;
    knudeIKnt.fdsWidth = override.fdsWidth;
    knudeIKnt.readOnly = override.readOnly ?? knudeIKnt.readOnly;
    knudeIKnt._presetValue = override.presetValue ?? knudeIKnt._presetValue;
    knudeIKnt.hidden = override.hidden ?? knudeIKnt.hidden;
    knudeIKnt.kopierTil = override.kopierTil ?? knudeIKnt.kopierTil;
    knudeIKnt.flyttesPaaTvaersAfXml = override.flyttesPaaTvaersAfXml ?? knudeIKnt.flyttesPaaTvaersAfXml;
    knudeIKnt.skalVisesSomMandatoryMenErDetIkkeIMetadata =
      override.skalVisesSomMandatoryMenErDetIkkeIMetadata ?? knudeIKnt.skalVisesSomMandatoryMenErDetIkkeIMetadata;
    knudeIKnt.mandatoryHvisIkke = override.mandatoryHvisIkke ?? knudeIKnt.mandatoryHvisIkke;
    knudeIKnt.forbiddenHvisIkke = override.forbiddenHvisIkke ?? knudeIKnt.forbiddenHvisIkke;
    knudeIKnt.sortering = override.sortering ?? knudeIKnt.sortering;
    knudeIKnt.hiddenFromReceipt = override.hiddenFromReceipt ?? knudeIKnt.hiddenFromReceipt;
    knudeIKnt.hiddenFromSummary = override.hiddenFromSummary ?? knudeIKnt.hiddenFromSummary;
    knudeIKnt.isMetadata = override.parent !== undefined && override.parent.toLocaleLowerCase().indexOf('metadata') !== -1;

    if (knudeIKnt.hidden === true) {
      this.removeFromOldParent(knt, knudeIKnt);
    } else if (override.parent !== undefined && knudeIKnt.parentId !== override.parent) {
      // Flyt knuden, da parent har ændret sig.
      this.insertNodeInParent(knt, override, knudeIKnt, fakeRoot);
      this.removeFromOldParent(knt, knudeIKnt);
    } else if ((override.parent !== undefined && knudeIKnt.parentId === override.parent) || override.index !== undefined) {
      // Samme parent, sorter efter index.
      const parentId = override.parent ?? knudeIKnt.parentId;
      const newParent = this.findParentOrFakeRoot(parentId, fakeRoot, knt);
      if (newParent.content === undefined) {
        newParent.content = [];
      }
      const oldIndex = newParent.content.findIndex(x => x.id === knudeIKnt.id);
      newParent.content.splice(oldIndex, 1);

      const indexInNewParent = newParent.content.findIndex(x => x.index > knudeIKnt.index);
      if (indexInNewParent === -1) {
        newParent.content.push(knudeIKnt);
      } else {
        newParent.content.splice(indexInNewParent, 0, knudeIKnt);
      }
    }

    knudeIKnt.parentId = override.parent ?? knudeIKnt.parentId;
  }

  private lavFeltUdfraMetadata(override: FieldKonfiguration, knudeSomMetadata: KonfigureretMetadata): KonfigureretContent {
    return {
      id: knudeSomMetadata.id,
      _label: override._label ?? knudeSomMetadata._label,
      contentType: override.contentType ?? knudeSomMetadata.contentType,
      displayType: override.displayType ?? knudeSomMetadata.displayType,
      description: override.description ?? knudeSomMetadata.description,
      _repeatable: knudeSomMetadata._repeatable,
      oprindeligRepeatable: knudeSomMetadata._repeatable,
      _presetValue: knudeSomMetadata._presetValue,
      presetValue: override.presetValue ?? knudeSomMetadata._presetValue,
      hidden: override.hidden ?? knudeSomMetadata.hidden,
      readOnly: override.readOnly ?? knudeSomMetadata.readOnly,
      index: override.index ?? 0,
      codeListFilter: override.codeListFilter ?? undefined,
      component: override.component ?? knudeSomMetadata.component,
      fdsWidth: override.fdsWidth,
      parentId: override.parent!,
      visualModelParentId: override.parent,
      isMetadata: true,
      hiddenFromReceipt: override.hiddenFromReceipt,
      hiddenFromSummary: override.hiddenFromSummary
    };
  }

  private removeFromOldParent(knt: KonfigureretNoticeType, knudeIKnt: KonfigureretContent) {
    let oldParentContent: KonfigureretContent[];
    if (knudeIKnt.parentId === ROOT_NODE_NAME) {
      oldParentContent = knt.content;
    } else {
      oldParentContent = this.findKnudeOrFail(knt.content, knudeIKnt.parentId).content!;
    }

    // Fjern fra gammel
    const oldIndex = oldParentContent.indexOf(knudeIKnt);
    if (oldIndex === -1) {
      throw new Error('Kunne ikke finde knude i liste: ' + knudeIKnt.id);
    }
    oldParentContent.splice(oldIndex, 1);
  }

  private insertNodeInParent(
    knt: KonfigureretNoticeType,
    override: FieldKonfiguration | GroupKonfiguration,
    knudeIKnt: KonfigureretContent,
    fakeRoot: KonfigureretContent
  ): KonfigureretContent {
    const newParent = this.findParentOrFakeRoot(override.parent!, fakeRoot, knt);

    if (newParent.content === undefined) {
      newParent.content = [];
    }
    const indexInNewParent = newParent.content.findIndex(x => x.index > knudeIKnt.index);

    // Bevar index for elementet efter det er indsat.
    const indexMinusIncrement = knudeIKnt.index - INDEX_INCREMENT;
    const newElement = this.deepCopyKonfigureretContent(knudeIKnt, indexMinusIncrement, newParent.id, knudeIKnt.visualModelParentId!);

    if (indexInNewParent === -1) {
      // Index = -1 betyder at der ikke fantes nogen i listen denne skulle indsættes før, skub til slutningen af listen.
      newParent.content.push(newElement);
    } else {
      // Indsæt element på passende plads før det element dens index er mindre end.
      newParent.content.splice(indexInNewParent, 0, newElement);
    }
    return newParent;
  }

  private moveNodeInParent(knt: KonfigureretNoticeType, parentId: string, knudeIKnt: KonfigureretContent, fakeRoot: KonfigureretContent) {
    const newParent = this.findParentOrFakeRoot(parentId, fakeRoot, knt);
    if (newParent.content === undefined) {
      newParent.content = [];
    }

    const fromIndex = newParent.content?.findIndex(x => x.id === knudeIKnt.id);
    if (fromIndex === -1) {
      throw new Error('Kunne ikke finde fromIndex ...');
    }
    // Fjern det gamle element før vi bestemmer det nye index
    const elementToMove = newParent.content.splice(fromIndex, 1)[0];

    // Find placering i nyt index
    const toIndex = newParent.content?.findIndex(x => x.index > knudeIKnt.index);
    if (toIndex === -1) {
      newParent.content.push(elementToMove);
    } else {
      newParent.content.splice(toIndex, 0, elementToMove);
    }
  }

  private findParentOrFakeRoot(parentId: string, fakeRoot: KonfigureretContent, knt: KonfigureretNoticeType) {
    return parentId === fakeRoot.id ? fakeRoot : this.findKnudeOrFail(knt.content, parentId);
  }

  private findKnudeOrFail(content: KonfigureretContent[], id: string): KonfigureretContent {
    const res = this.findKnude(content, id);
    if (!res) {
      throw new Error(`Kan ikke finde knude med id: '${id}'`);
    }
    return res;
  }

  private findMetadata(knt: KonfigureretNoticeType, id: string): KonfigureretMetadata | undefined {
    for (const metadata of knt.metadata) {
      if (metadata.id === id) {
        return metadata;
      }
    }

    return undefined;
  }

  private findKnude(content: KonfigureretContent[], id: string): KonfigureretContent | undefined {
    // Refactor: Skal dette også skiftes til et hashmap?
    for (const child of content) {
      if (child.id === id) {
        return child;
      }

      if (child.content) {
        const found = this.findKnude(child.content, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  private deepCopyNoticeType(notice: NoticeType): KonfigureretNoticeType {
    // Refactor: Brug structuredClone og patch med ny info?

    // Deep copy of metadata array
    const copiedMetadata: KonfigureretMetadata[] = notice.metadata.map(md =>
      // Assuming KonfigureretMetadata is a class with a constructor that accepts Metadata
      ({
        contentType: md.contentType,
        description: md.description,
        displayType: md.displayType,
        _label: md._label,
        id: md.id,
        _presetValue: md._presetValue,
        _repeatable: md._repeatable,
        hidden: md.hidden,
        readOnly: md.readOnly
      })
    );

    // Deep copy of content array
    let currentIndex = -INDEX_INCREMENT;
    const copiedContent: KonfigureretContent[] = notice.content.map(ct => {
      currentIndex += INDEX_INCREMENT;
      return this.deepCopyKonfigureretContent(ct, INDEX_INCREMENT, ROOT_NODE_NAME, ROOT_NODE_NAME);
    });

    // Copying other properties
    return {
      ublVersion: notice.ublVersion,
      sdkVersion: notice.sdkVersion,
      metadataDatabase: { ...notice.metadataDatabase }, // Deep copy if MetadataDatabase is an object
      noticeId: notice.noticeId,
      metadata: copiedMetadata,
      content: copiedContent
    };
  }

  private deepCopyKonfigureretContent(
    ct: Content | KonfigureretContent,
    previousIndex: number,
    parentId: string,
    latestNodeId: string
  ): KonfigureretContent {
    const konfigureretContent = ct as KonfigureretContent;

    const result: KonfigureretContent = {
      id: ct.id,
      _label: ct._label,
      contentType: ct.contentType,
      displayType: ct.displayType,
      description: ct.description,
      _captionFieldId: ct._captionFieldId,
      _identifierFieldId: ct._identifierFieldId,
      _idScheme: ct._idScheme,
      _idSchemes: ct._idSchemes,
      _presetValue: konfigureretContent._presetValue ?? ct._presetValue,
      _repeatable: ct._repeatable,
      oprindeligRepeatable: konfigureretContent.oprindeligRepeatable ?? ct._repeatable,
      _schemeName: ct._schemeName,
      collapsed: ct.collapsed,
      hidden: ct.hidden,
      nodeId: ct.nodeId,
      readOnly: ct.readOnly,
      unpublishCode: ct.unpublishCode,
      unpublishFieldId: ct.unpublishFieldId,
      unpublishGroupId: ct.unpublishGroupId,
      index: previousIndex + INDEX_INCREMENT,
      parentId: parentId,
      visualModelParentId: ct.nodeId ?? latestNodeId,
      codeListFilter: konfigureretContent?.codeListFilter,
      component: konfigureretContent?.component,
      fdsWidth: konfigureretContent?.fdsWidth,
      defaultRepeats: konfigureretContent?.defaultRepeats ?? 0,
      minRepeats: konfigureretContent?.minRepeats,
      forceVisOverskrift: konfigureretContent?.forceVisOverskrift,
      kopierTil: konfigureretContent?.kopierTil,
      flyttesPaaTvaersAfXml: konfigureretContent?.flyttesPaaTvaersAfXml,
      opretKunManuelt: konfigureretContent?.opretKunManuelt,
      skalVisesSomMandatoryMenErDetIkkeIMetadata: konfigureretContent?.skalVisesSomMandatoryMenErDetIkkeIMetadata,
      mandatoryHvisIkke: konfigureretContent?.mandatoryHvisIkke,
      forbiddenHvisIkke: konfigureretContent?.forbiddenHvisIkke,
      sortering: konfigureretContent?.sortering,
      isMetadata: konfigureretContent?.isMetadata,
      hiddenFromReceipt: konfigureretContent?.hiddenFromReceipt,
      hiddenFromSummary: konfigureretContent.hiddenFromSummary,
      choiceGroupConfiguration: konfigureretContent.choiceGroupConfiguration
    };
    let currentIndex = -INDEX_INCREMENT;
    result.content = ct.content?.map(x => {
      currentIndex += INDEX_INCREMENT;
      return this.deepCopyKonfigureretContent(x, currentIndex, ct.id, ct.nodeId ?? latestNodeId);
    });
    return result;
  }
}

export const eformsFormularKonfigurationService = new EFormsFormularKonfigurationService();

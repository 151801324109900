import { reactive } from 'vue';

import { MainRoute } from '@/main/enums/mainRoute.enum';
import { Path } from '@/main/enums/path.enum';
import router from '@/main/router';
import { brugerprofilService } from '@/main/services/brugerprofil.service';
import { environmentConfig } from '@/main/services/environmentConfig.service';
import AuthConfigService, {
  AuthConfigOptions,
  getAutologoutTimeSeconds,
  getNotificationTimeSeconds
} from '@/main/services/s9-oidc/auth.config.service';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { virksomhedsRolleService } from '@/main/services/virksomhedsrolle.service';
import { useAuthPersistenceStore } from '@/main/stores/auth-persistence.state';
import { useModalStore } from '@/main/stores/modal.state';

export class AuthState {
  claims = {
    userinfo: {
      name: null,
      'dk:gov:cvr': null,
      'dk:gov:org_name': null,
      'dk:gov:virk:roles': null,
      'dk:gov:virk:email': null
    },
    id_token: {
      name: null,
      'dk:gov:cvr': null,
      'dk:gov:org_name': null,
      'dk:gov:virk:roles': null,
      'dk:gov:virk:email': null
    }
  };

  /**
   * Nuværende state for bruger
   */
  state: AuthService | undefined = undefined;

  /**
   * Henter config og newer state op med AuthService
   * med de korrekte options (env variabler)
   * Skal kaldes inden app starter
   */
  async initState() {
    // TODO: Tjek om denne allerede er ved at køre, eller er kørt?

    const loginType = sessionStorage.getItem('loginType') ?? 'default';
    let clientId;

    switch (loginType) {
      case 'eidNatural':
        clientId = environmentConfig.variables.sector9.mitudbudeidasnatural;
        break;
      case 'eidLegal':
        clientId = environmentConfig.variables.sector9.mitudbudeidasLegal;
        break;
      case 'ssi':
        clientId = environmentConfig.variables.sector9.mitudbudssi;
        break;
      case 'mitId':
      default:
        clientId = environmentConfig.variables.sector9.mitIdClient;
    }
    const options: AuthConfigOptions = {
      clientId: clientId,
      sector9AuthUrl: environmentConfig.variables.sector9.authUrl,
      redirectUri: '' + environmentConfig.variables.baseUrl.client + environmentConfig.variables.sector9.redirectEndpoint,
      postLogoutRedirectUri: environmentConfig.variables.baseUrl.client,

      claims: this.claims
    };

    const authConfig = new AuthConfigService(options);
    this.state = new AuthService(authConfig.userManagerSettings);
    if (this.state.userManager) {
      this.state.userManager.events.addAccessTokenExpired(function () {
        console.log('⏰ token udløb uden aktion ... Logger bruger ud.');
        authState.initState();
        localStorage.removeItem('authPersistence');
        window.location.reload();
      });
      this.state.userManager.events.addAccessTokenExpiring(function () {
        console.log('⏰ token udløber snart, viser modal');
        const modalStore = useModalStore(window.pinia);
        modalStore.openLoginExpiringModal();
      });
    }

    if (this.state && !this.state.user) {
      const authStore = useAuthPersistenceStore(window.pinia);
      console.log('🔐 Setting authState to authStore saved state', authStore.getUser);
      if (authStore.getUser == null) {
        console.log('🔐 Ingen gemt bruger fundet.');
        return;
      }

      const loginExpiresIn = authStore.getUser?.expires_in ?? 0;
      // Hvis brugerens login er inden for ~5 min af at udløbe, så log dem ud.
      if (loginExpiresIn < getAutologoutTimeSeconds()) {
        const date = new Date((authStore.getUser.expires_at ?? 0) * 1000);
        console.log("🔐 Gemt bruger's login var udløbet eller udløber snart: " + date);

        this.state.user = null;
        // Fjern user fra authStore (log ud af MitUdbud)
        localStorage.removeItem('authPersistence');
        authStore.$reset();

        // Send til forside
        router.push({
          name: MainRoute.FORSIDE
        });
      } else {
        console.log('🔐 Gemt bruger gendannet');
        this.state.user = authStore.getUser;
      }

      // Hvis brugeren er logget ind, men snart udløber, så vis modal igen.
      // Undlad at vise modal hvis vi er på login callbacket.
      if (window.location.pathname == Path.S9_AUTH_REDIRECT) {
        console.log('Login igang. undlader at vise modal ...');
      } else if (this.state.user && loginExpiresIn < getNotificationTimeSeconds()) {
        // Brugerens login udløber snart, giv dem besked om at de kan forny
        console.log('⏰ token udløber snart, viser modal');
        setTimeout(() => {
          const modalStore = useModalStore(window.pinia);
          modalStore.openLoginExpiringModal();
        }, 1000);
      }
    }

    authState.hentVirksomhedsRolleHvisMangler();
  }

  async hentVirksomhedsRolleHvisMangler(): Promise<void> {
    const cvrNummer = this.state?.getProfile('dk:gov:cvr');
    if (this.state?.user !== undefined) {
      if (cvrNummer !== undefined && this.state.virksomhedRolleDto == undefined) {
        return virksomhedsRolleService.hentForCvr(cvrNummer).then(hentetRolle => {
          if (this.state) {
            this.state.virksomhedRolleDto = hentetRolle;
          }
        });
      }
    }
  }

  async hentBrugerprofilVedLoginOgVisModalHvisMangler() {
    // Forsøg ikke hvis brugeren ikke er logget ind.
    if (this.state?.user == null) {
      return;
    }
    await brugerprofilService.hentBrugerprofil();
  }
}

/**
 * Laver Auth Service om til at være reaktive
 * og nedestående blive til en singleton state, der kan bruges på tværs
 */
export const authState = reactive(new AuthState());

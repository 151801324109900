<template>
  <nav class="mud-top-nav" :aria-label="$t('topnav-aria_label')">
    <div class="navbar navbar-primary">
      <div class="navbar-inner container pl-md-0">
        <ul class="nav-primary d-flex align-items-center flex-row flex-wrap">
          <li v-if="!isMobile">
            <BaseFunctionLinks
              v-if="!bruger.loggetInd || (bruger.loggetInd && !!isOrganisationAttached)"
              :button-styles="'link-top-nav-style'"
              :link-path="Path.ORDREGIVER"
              :click-handler-if-logged-in="navigateHandlerIfLoggedIn"
              :show-link-if-logged-in="() => !!isOrganisationAttached"
              :show-link-if-not-logged-in="() => false"
              :show-link-if-logged-in-link-label="$t('topnav.overblik.link.label')"
              :show-link-if-not-logged-in-link-label="$t('topnav.overblik.link.label')"
              :cypress-data="'oversigt-aaben-ordregiver'"
              :click-handler-if-not-logged-in="() => visningUtil.openLogin(Path.ORDREGIVER)"
              :show-icon="false" />
          </li>
          <li v-if="!isMobile">
            <BaseFunctionLinks
              :button-styles="'link-top-nav-style'"
              :link-path="Path.UDBUDSAGENT"
              :click-handler-if-logged-in="navigateHandlerIfLoggedIn"
              :show-link-if-logged-in="() => bruger?.loggetInd"
              :show-link-if-not-logged-in="() => !bruger?.loggetInd"
              :show-link-if-logged-in-link-label="$t('topnav.overblik.link.label-anden')"
              :show-link-if-not-logged-in-link-label="$t('topnav.overblik.link.label-anden')"
              :cypress-data="'oversigt-aaben-udbudsagent'"
              :click-handler-if-not-logged-in="() => visningUtil.openLogin(Path.UDBUDSAGENT)"
              :show-icon="false" />
          </li>
          <li v-if="harKonfigModulAdgang() && !isMobile">
            <router-link :to="Path.KONFIG_DRIFTMEDDELELSE" custom v-slot="{ href, navigate, isActive }">
              <a
                @click="navigate"
                :href="href"
                class="nav-link menu-item"
                :class="{ 'router-link-active': isActive }"
                rel="noopener"
                :aria-label="$t('internal.topnav.konfigurationsmodul.link.label')">
                <span class="d-none d-md-block">{{ $t('internal.topnav.konfigurationsmodul.link.label') }}</span>
                <span class="d-md-none">
                  <svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#apps" /></svg>
                </span>
              </a>
            </router-link>
          </li>
          <li>
            <div v-if="isMobile">
              <template v-if="harAlleRoller()">
                <button type="button" class="burger-menu nav-link" @click="toggleBurgerMenuBtn">
                  <div class="d-flex align-items-center">
                    <span class="d-md-none">
                      <svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#burger-menu-header" /></svg>
                    </span>
                  </div>
                </button>
                <div class="logout-container overflow-menu burger-menu-overflow burger-menu-list" v-if="showBurgerMenuBtn">
                  <ul class="overflow-list">
                    <li>
                      <BaseFunctionLinks
                        :link-path="Path.UDBUDSAGENT"
                        :click-handler-if-logged-in="navigateHandlerIfLoggedIn"
                        :show-link-if-logged-in="() => bruger?.loggetInd"
                        :show-link-if-not-logged-in="() => !bruger?.loggetInd"
                        :show-link-if-logged-in-link-label="$t('topnav.overblik.link.label-anden')"
                        :show-link-if-not-logged-in-link-label="$t('topnav.overblik.link.label-anden')"
                        :cypress-data="'oversigt-aaben-udbudsagent'"
                        :click-handler-if-not-logged-in="() => visningUtil.openLogin(Path.UDBUDSAGENT)"
                        :show-icon="false" />
                    </li>
                    <li v-if="harKonfigModulAdgang()">
                      <router-link :to="Path.KONFIG_DRIFTMEDDELELSE" custom v-slot="{ href, navigate }">
                        <a @click="navigate" :href="href" rel="noopener" :aria-label="$t('internal.topnav.konfigurationsmodul.link.label')">
                          <span class="d-md-block">{{ $t('internal.topnav.konfigurationsmodul.link.label') }}</span>
                        </a>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </li>
          <li>
            <button
              v-if="!bruger?.loggetInd"
              @click="visningUtil.openLogin()"
              type="button"
              class="nav-link login-btn d-flex"
              :aria-label="$t('topnav.login.login-label')"
              data-cy="login-knap">
              <div class="d-flex align-items-center">
                <div class="login-icon">
                  <svg class="icon-svg" focusable="false" aria-hidden="true">
                    <use xlink:href="#lock" />
                  </svg>
                </div>
                <span class="d-none d-md-inline">{{ $t('topnav.login.login-label') }}</span>
              </div>
            </button>
            <template v-else-if="bruger">
              <div v-if="isMobile">
                <button type="button" class="nav-link login-btn logged-in" @click="toggleLogoutBtn" data-cy="vis-logout">
                  <div class="d-flex align-items-center">
                    <div class="profile-icon">
                      <svg class="icon-svg" focusable="false" aria-hidden="true">
                        <use xlink:href="#person-circle" />
                      </svg>
                    </div>
                  </div>
                </button>
                <div class="logout-container overflow-menu" v-if="showLogoutBtn">
                  <ul class="overflow-list">
                    <li>
                      <router-link :to="Path.INDSTILLINGER_PROFIL" class="function-link">
                        <svg class="icon-svg" focusable="false" aria-hidden="true">
                          <use xlink:href="#internal-link-arrow"></use>
                        </svg>
                        {{ $t('topnav.login.profil-link') }}
                      </router-link>
                    </li>
                  </ul>
                  <button type="button" @click="onLogout()" class="button button-primary logout-btn" data-cy="logout">
                    <svg class="icon-svg" focusable="true" aria-hidden="true">
                      <use xlink:href="#lock-open" />
                    </svg>
                    {{ $t('topnav.login.logud-label') }}
                  </button>
                </div>
              </div>
              <div v-else>
                <button type="button" class="nav-link login-btn logged-in" @click="toggleLogoutBtn" data-cy="vis-logout">
                  <div class="d-flex">
                    <div class="profile-icon">
                      <svg class="icon-svg" focusable="false" aria-hidden="true">
                        <use xlink:href="#person-circle" />
                      </svg>
                    </div>
                    <div class="elipsis-tekst-overflow logged-in__text align-text-left">
                      <span class="user-name clearfix" data-cy="bruger-navn-visning">{{ bruger.navn !== null ? bruger.navn : '' }}</span>
                      <span class="organisation-name" data-cy="bruger-ekstra-visning">
                        <template v-if="bruger.virksomhedsnavn">{{ bruger.virksomhedsnavn }}</template>
                        <template v-else-if="bruger.cvrNummer">
                          {{ $t('topnav.login.cvr-prefix') }}
                          {{ bruger.cvrNummer }}
                        </template>
                        <template v-else-if="bruger.loginMethod === 'SSI'">{{ bruger.email }}</template>
                        <template v-else-if="bruger.privatPerson">{{ $t('topnav.login.privatperson-label') }}</template>
                        <template v-else>
                          {{ bruger.email }}
                        </template>
                      </span>
                    </div>
                    <div class="arrow-icon">
                      <svg class="icon-svg" focusable="false" aria-hidden="true">
                        <use :xlink:href="showLogoutBtn ? '#expand-less' : '#expand-more'" />
                      </svg>
                    </div>
                  </div>
                </button>
                <div class="logout-container overflow-menu" v-if="showLogoutBtn">
                  <ul class="overflow-list">
                    <li>
                      <router-link :to="Path.INDSTILLINGER_PROFIL" class="function-link">
                        <svg class="icon-svg" focusable="false" aria-hidden="true">
                          <use xlink:href="#internal-link-arrow"></use>
                        </svg>
                        {{ $t('topnav.login.profil-link') }}
                      </router-link>
                    </li>
                  </ul>
                  <button type="button" @click="onLogout()" class="button button-primary logout-btn" data-cy="logout">
                    <svg class="icon-svg" focusable="true" aria-hidden="true">
                      <use xlink:href="#lock-open" />
                    </svg>
                    {{ $t('topnav.login.logud-label') }}
                  </button>
                </div>
              </div>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { ComputedRef, computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { Path } from '@/main/enums/path.enum';
import { Bruger } from '@/main/models/bruger.model';
import { authState } from '@/main/stores/auth.state';
import { computedBruger } from '@/main/stores/auth.state.bruger';
import { useSprogStore } from '@/main/stores/sprog.state';
import { visningUtil } from '@/main/utils/visning-util';

import { Visning } from '../enums/visning.enum';
import BaseFunctionLinks from './base/BaseFunctionLinks.vue';

const route = useRoute();

const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => screenWidth.value < Visning.GRID_BREAKPOINT_MD);

const bruger: ComputedRef<Bruger> = computedBruger();
const showLogoutBtn = ref(false);
const showBurgerMenuBtn = ref(false);
const isOrganisationAttached = computed(() => bruger.value.organisationsProfil);

const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

async function onLogout() {
  const sprogStore = useSprogStore();
  sprogStore.updateRedaktorNoegle(false);
  await authState?.state?.logout().catch(err => {
    console.error(err);
  });
}

function harAlleRoller() {
  const alleRoller = [
    'MU_TEKSTREDAKTOER',
    'MU_TEKSTREDAKTOER_ADM',
    'MU_SOEGESPECIALIST',
    'MU_SOEGESPECIALIST_ADM',
    'MU_DRIFTSREDAKTOER',
    'MU_SUPPORT'
  ];
  return bruger.value.roller.some(x => alleRoller.includes(x));
}

function harKonfigModulAdgang() {
  const rollerTilKonfigModul = ['MU_DRIFTSREDAKTOER', 'MU_SUPPORT'];
  return bruger.value.roller.some(x => rollerTilKonfigModul.includes(x));
}

function toggleLogoutBtn() {
  showLogoutBtn.value = !showLogoutBtn.value;
}

function toggleBurgerMenuBtn() {
  showBurgerMenuBtn.value = !showBurgerMenuBtn.value;
}

// Update the handleClickOutside to manage both logout and burger menu
function handleClickOutside(event: MouseEvent) {
  const logoutContainer = document.querySelector('.logout-container');
  const loginBtn = document.querySelector('.login-btn.logged-in');
  const burgerMenu = document.querySelector('.burger-menu');
  const burgerMenuOverflow = document.querySelector('.burger-menu-overflow');

  if (logoutContainer && !logoutContainer.contains(event.target as Node) && loginBtn && !loginBtn.contains(event.target as Node)) {
    showLogoutBtn.value = false;
  }

  if (burgerMenuOverflow && !burgerMenuOverflow.contains(event.target as Node) && burgerMenu && !burgerMenu.contains(event.target as Node)) {
    showBurgerMenuBtn.value = false;
  }
}

const navigateHandlerIfLoggedIn = (navigate: () => void) => {
  navigate();
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style lang="scss" scoped>
@import '../styles/_topNav';
</style>

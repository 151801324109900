import { parse } from 'path';

class InputFormatUtil {
  public static fjernWhitespace(value: string): string {
    let norm = value.trim();
    const remove = new RegExp(' ', 'g');
    norm = norm.replaceAll(remove, '');
    return norm;
  }

  public static formaterTelefon(value: string): string {
    let norm = value.trim();
    if (norm.startsWith('00')) {
      norm = norm.replace('00', '+');
    }
    const remove = new RegExp('[ ()]', 'g');
    norm = norm.replaceAll(remove, '');
    if (norm.startsWith('00')) {
      norm = norm.replace('00', '+');
    }
    return norm;
  }

  public static formaterHeltalVisning(value: string): string {
    let norm = value.trim();
    const remove = new RegExp('[., ]', 'g');
    norm = norm.replaceAll(remove, '');
    const parsed = parseInt(norm);
    if (!isNaN(parsed)) {
      return parsed.toLocaleString('da-DK', { maximumFractionDigits: 0 });
    } else {
      return value;
    }
  }

  public static formaterHeltalVisningFraUSFormatTilDK(value: string): string {
    const parsed = parseFloat(value);
    if (!isNaN(parsed)) {
      return parsed.toLocaleString('da-DK', { maximumFractionDigits: 0 });
    } else {
      return value;
    }
  }

  public static erValidValutaFormatAmerican(input: string): boolean {
    if (this.isValidSpecificFormat(input)) {
      return true;
    }
    const trimmedInput = input.trim();

    const regexPattern = /^-?(\d{1,3}(?:[ ,]\d{3})*)(\.\d{2})?$/;
    if (!regexPattern.test(trimmedInput)) {
      return false;
    }
    const hasMultipleDecimals = (trimmedInput.match(/\./g) || []).length > 1;
    const hasMultipleCommas = (trimmedInput.match(/,/g) || []).length > 1;
    const hasMultipleSpaces = (trimmedInput.match(/ /g) || []).length > 1;

    if (hasMultipleDecimals || (hasMultipleCommas && hasMultipleSpaces)) {
      return false;
    }
    const sanitizedInput = trimmedInput.replace(/^-/, '').replace(/,/g, '').replace(/ /g, '');
    const parts = sanitizedInput.split('.');
    const decimalPart = parts.length > 1 ? parts[1] : '';
    if (decimalPart.length > 2) {
      return false;
    }

    return true;
  }

  public static erValidValutaFormatScandinavian(input: string): boolean {
    if (this.isValidSpecificFormat(input)) {
      return true;
    }
    const trimmedInput = input.trim();
    const regexPattern = /^-?(\d{1,3}(?:[.\s]\d{3})*)(,\d{1,2})?$/;
    if (!regexPattern.test(trimmedInput)) {
      return false;
    }

    const hasMultipleCommas = (trimmedInput.match(/,/g) || []).length > 1;
    const hasMultipleDots = (trimmedInput.match(/\./g) || []).length > 1;
    const hasMultipleSpaces = (trimmedInput.match(/\s/g) || []).length > 1;

    if (hasMultipleCommas || (hasMultipleDots && hasMultipleSpaces)) {
      return false;
    }

    const sanitizedInput = trimmedInput.replace(/^-/, '').replace(/\./g, '').replace(/\s/g, '');
    const parts = sanitizedInput.split(',');
    const decimalPart = parts.length > 1 ? parts[1] : '';
    if (decimalPart.length !== 0 && decimalPart.length !== 2) {
      return false;
    }
    return true;
  }

  public static isValidSpecificFormat(input: string): boolean {
    //komma eller punktum før de 2 sidste tal i input
    const regex = /^\d+([,.]\d{2})?$/;
    return regex.test(input);
  }

  public static isValidSpecificFormatSkandinavisk(input: string): boolean {
    //komma før de 2 sidste tal i input
    const regex = /^\d+([,]\d{2})?$/;
    return regex.test(input);
  }

  public static hasNoSeparators(value: string) {
    const regex = /^-?\d+$/;
    return regex.test(value);
  }

  public static formaterValutaVisningMedPunktumOgKomma(inpValue: string, minFracDigits = 2, maxFracDigits = 2): string {
    let value = this.valutaStringTilFloatFixed(inpValue, 1, 2);
    if (typeof value == 'number') {
      if (isNaN(value)) {
        value = inpValue;
      } else {
        value = value.toLocaleString('da-DK', { minimumFractionDigits: minFracDigits, maximumFractionDigits: maxFracDigits });
      }
    }

    return value;
  }

  public static toEFormsNumberFormat(value: string): string {
    const valuta = this.valutaStringTilFloatFixed(value, 0, 99);
    if (typeof valuta === 'number' && !isNaN(valuta)) {
      return valuta.toString();
    } else {
      return value;
    }
  }

  private static valutaStringTilFloatFixed(value: string, minFracDigits: number, maxFracDigits: number): number | string {
    const erTalUdenDecimaler = /^-?\d{1,3}([., ]\d{3})+$/;
    const harDecimalerRegex = new RegExp(String.raw`^(-?)([\d., ]*?)([\\.,]\d{${minFracDigits},${maxFracDigits}})?$`);

    if (erTalUdenDecimaler.test(value)) {
      const rigtigTal = value.replaceAll(/[,. ]/g, '');
      return parseFloat(rigtigTal);
    } else if (harDecimalerRegex.test(value)) {
      // This input string has decimals, we need to interpret it as such
      const matches = harDecimalerRegex.exec(value);
      if (matches != null) {
        const positivNegativDel = matches[1];
        const heltalsDel = matches[2];
        const decimalDel = matches[3] ?? '';

        const decimalMedPunktum = decimalDel !== '' ? '.' + decimalDel.replaceAll(/\D/g, '') : '';

        const rigtigTal = positivNegativDel + heltalsDel.replaceAll(/\D/g, '') + decimalMedPunktum;

        return parseFloat(rigtigTal);
      }
    } else if (/(\d,. )+/.test(value)) {
      // Input string has no decimals
      const remove = /[ \\.]/g;

      const parsed = parseFloat(value.replaceAll(remove, ''));
      return isNaN(parsed) ? value : parsed;
    }

    return value;
  }
}

export default InputFormatUtil;

<template>
  <div class="modal-body" data-cy="udbudsagent-slet-modal">
    <BaseBesked v-if="generiskBackendFejl" :type="'error'" :titel="$t('udsagent.fejlbesked.slet')" :vis-ikon="true">
      {{ $t('udbudsagent.fejlbesked.tekst') }}
    </BaseBesked>
    <ContentfulRigTekst :document="$tm('udbudsagentSletBekraeftelseModal.broedtekst')" />
  </div>
  <div class="modal-footer">
    <button type="button" @click="sletUdbudsAgent()" class="button button-primary" data-cy="udbudsagent-slet-knap">
      {{ $t('udbudsagent-sletning-bekraeftelse.slet') }}
    </button>
    <button type="button" @click="modalStore.closeModal()" class="button button-secondary" data-cy="udbudsagent-annuller-knap">
      {{ $t('annuller.tekst') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import BaseBesked from '@/main/components/base/BaseBesked.vue';
import { GemtSoegningDTO } from '@/main/models/generated';
import { soegningService } from '@/main/services/soegning.service';
import { useModalStore } from '@/main/stores/modal.state';
import { useToastStore } from '@/main/stores/toast.state';

const modalStore = useModalStore(window.pinia);
const generiskBackendFejl = ref(false);
const toastStore = useToastStore(window.pinia);
const { t } = useI18n();

const props = defineProps({
  element: {
    type: Object as PropType<GemtSoegningDTO>,
    required: true
  }
});
const toastbodySuccess = t('udbudsagent.success.toastbody', { soegningNavn: t(props.element.soegningNavn) });
const toastbodyFailure = t('udbudsagent.fejlet.toastbody', { soegningNavn: t(props.element.soegningNavn) });

const isDeleting = ref(false);

async function sletUdbudsAgent() {
  if (isDeleting.value) {
    return;
  }
  isDeleting.value = true;
  try {
    const soegningId = props.element.soegningId as number;
    const response = await soegningService.slet(soegningId);
    if (!response) {
      generiskBackendFejl.value = true;
      toastStore.createToast('error', 'udbudsagent.fejlet.toastheader', toastbodyFailure);
    } else {
      modalStore.closeModal();
      toastStore.createToast('success', 'udbudsagent.success.toastheader', toastbodySuccess);
    }
  } catch (error) {
    generiskBackendFejl.value = true;
    toastStore.createToast('error', 'udbudsagent.fejlet.toastheader', toastbodyFailure);
  } finally {
    isDeleting.value = false;
  }
}
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>

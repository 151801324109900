import { VisualModelNode } from '@models';

import { FieldType } from '@/main/enums/eforms/eformsFieldType.enum';
import { ValideringFejlDetailer, useEformsModelStore } from '@/main/stores/eforms-model.state';
import { eformsDataUtil } from '@/main/utils/eforms-data-util';
import Validation from '@/main/utils/validation';

class EFormsValidationUtil {
  public validateNumberFields(): Map<string, ValideringFejlDetailer> {
    const eformsModelStore: ReturnType<typeof useEformsModelStore> = useEformsModelStore(window.pinia);
    const errors: Map<string, ValideringFejlDetailer> = new Map();
    eformsDataUtil.executePredicateOnVisualModelNodesRec(eformsModelStore.model, this.validateField(eformsModelStore, errors));
    return errors;
  }

  private validateField(
    eformsModelStore: ReturnType<typeof useEformsModelStore>,
    errors: Map<string, ValideringFejlDetailer>
  ): (vm: VisualModelNode) => void {
    return (vm): void => {
      const field = eformsModelStore.f.dataMap?.get(vm.contentId!);
      if (
        field == undefined ||
        !(field.type == FieldType.AMOUNT || field.type == FieldType.MEASURE || field.type == FieldType.INTEGER || field.type == FieldType.NUMBER)
      ) {
        return;
      }

      if (vm.value == undefined || vm.value === '') {
        return;
      }

      console.log(`validateNumberFields: Tjekker: '${vm.contentId}-${vm.contentCount}' - Har værdi: '${vm.value}'`);
      // Skal valideres til at matche XSD:
      // Amount:  udt:AmountType                                  => ccts-cct:AmountType   => xsd:decimal
      // Measure: udt:MeasureType                                 => ccts-cct:MeasureType  => xsd:decimal
      // Integer: MaximumOperatorQuantityType => udt:QuantityType => ccts-cct:QuantityType => xsd:decimal
      // http://www.datypic.com/sc/xsd/t-xsd_decimal.html
      const validationResult = Validation.xsdDecimal(vm.value);
      if (validationResult != '') {
        errors.set(`${vm.contentId}-${vm.contentCount}`, {
          details: 'null',
          ruleId: `input.${validationResult}`
        });
        console.log(
          `validateNumberFields: Fejl på felt: '${vm.contentId}-${vm.contentCount}' - Har værdi: '${vm.value}' - Fejl: ${validationResult}`
        );
      }
    };
  }
}
export const eformsValidationUtil = new EFormsValidationUtil();

import { RouteRecordRaw } from 'vue-router';

import { EformsNoticeTypeProps as EFormsNoticeTypeProps } from '@/main/components/eForms/typer/eforms-notice-type-props';
import { CommonRoute } from '@/main/enums/commonRoute.enum';
import { InformationsideRoute } from '@/main/enums/informationside.enum';
import { MainRoute } from '@/main/enums/mainRoute.enum';
import { MetaDescription } from '@/main/enums/metaDescription.enum';
import { Path } from '@/main/enums/path.enum';
import { BekendtgoerelseNoegle } from '@/main/models/generated';
import { authState } from '@/main/stores/auth.state';

const defaultRoutes: RouteRecordRaw[] = [
  {
    path: Path.BASE,
    name: MainRoute.FORSIDE,
    component: () => import('@/main/views/Forside.vue'),
    meta: {
      title: MainRoute.UDBUD_DK,
      description: MetaDescription.FORSIDE,
      isPublic: true
    }
  },
  {
    path: Path.EMPTY,
    name: MainRoute.FORSIDE,
    component: () => import('@/main/views/Forside.vue'),
    meta: {
      title: MainRoute.UDBUD_DK,
      description: MetaDescription.FORSIDE,
      isPublic: true
    }
  },
  {
    path: Path.PLAYGROUND,
    name: MainRoute.PLAYGROUND,
    component: () => import('@/main/views/Playground.vue'),
    meta: {
      isPublic: false
    }
  },
  {
    path: Path.DEMO,
    name: MainRoute.DEMOEKSEMPLER,
    component: () => import('@/main/views/DemoEksempler.vue'),
    meta: {
      isPublic: false
    }
  },
  {
    path: Path.DEMO_EFORMS_FELTER_DEMO,
    name: MainRoute.EFORMSFELTERDEMO,
    component: () => import('@/main/views/EFormsFormular/EFormsFelterDemo.vue'),
    meta: {
      isPublic: false
    }
  },
  {
    path: Path.DEMO_EFORMS_NOTICETYPE_DEMO,
    name: MainRoute.EFORMSNOTICETYPEDEMO,
    component: () => import('@/main/views/EFormsFormular/EFormsNoticeTypeDemo.vue'),
    meta: {
      isPublic: false
    }
  },
  {
    path: Path.INFORMATION,
    name: InformationsideRoute.INFORMATIONSIDE,
    component: () => import('@/main/components/Informationside.vue'),
    props: true,
    meta: {
      title: InformationsideRoute.INFORMATIONSIDE,
      description: MetaDescription.INFORMATION,
      isPublic: true
    }
  },
  {
    path: Path.INDSTILLINGER,
    name: MainRoute.INDSTILLINGER,
    meta: {
      requiredAuth: true,
      title: MainRoute.INDSTILLINGER,
      description: MetaDescription.INDSTILLINGER,
      isPublic: false
    },
    component: () => import('@/main/views/Indstillinger.vue'),
    children: [
      {
        path: Path.PROFIL,
        component: () => import('@/main/views/Profil.vue')
      }
    ]
  },
  {
    path: Path.KONFIG,
    name: MainRoute.KONFIGURATION,
    meta: {
      title: MainRoute.KONFIGURATION,
      isPublic: false,
      requiredRoles: [
        'MU_TEKSTREDAKTOER',
        'MU_TEKSTREDAKTOER_ADM',
        'MU_SOEGESPECIALIST',
        'MU_SOEGESPECIALIST_ADM',
        'MU_DRIFTSREDAKTOER',
        'MU_SUPPORT'
      ]
    },
    component: () => import('@/main/views/konfigurationsmodul/Konfiguration.vue'),
    children: [
      /*
      {
        path: Path.SYNONYMER,
        component: () => import('@/main/views/konfigurationsmodul/Synonymer.vue')
      },
      {
        path: Path.REWRITES,
        component: () => import('@/main/views/konfigurationsmodul/Rewrites.vue')
      },
      {
        path: Path.STOPORD,
        component: () => import('@/main/views/konfigurationsmodul/Stopord.vue')
      },
      {
        path: Path.VAEGTNING,
        component: () => import('@/main/views/konfigurationsmodul/Vaegtning.vue')
      },
      */
      {
        path: Path.DRIFTMEDDELELSE,
        component: () => import('@/main/views/konfigurationsmodul/Driftsmeddelelse.vue')
      }
    ]
  },

  {
    path: Path.SOEG,
    name: MainRoute.SOEGNING,
    component: () => import('@/main/views/Soegning.vue'),
    props: route => ({ query: route.query.soegeTekst }),
    meta: {
      title: MainRoute.SOEGNING,
      description: MetaDescription.SOEG,
      isPublic: true
    }
  },
  {
    path: Path.DETALJEVISNING,
    component: () => import('@/main/views/Detaljevisning.vue'),
    props: true,
    meta: {
      title: MainRoute.DETALJEVISNING,
      isPublic: true
    }
  },
  {
    path: Path.ORDREGIVER,
    component: () => import('@/main/views/oversigtsmodul/AnnonceOverblik.vue'),
    props: true,
    meta: {
      title: MainRoute.ORDREGIVER,
      description: MetaDescription.ORDREGIVER,
      requiredOrganisationsProfil: true,
      isPublic: false,
      requiredAuth: true
    }
  },
  {
    path: Path.UDBUDSAGENT,
    component: () => import('@/main/views/oversigtsmodul/OvervaagUdbud.vue'),
    props: true,
    meta: {
      title: MainRoute.UDBUDSAGENT,
      description: MetaDescription.UDBUDSAGENT,
      requiredOrganisationsProfil: false,
      isPublic: false,
      requiredAuth: true
    }
  },
  {
    path: Path.OPRET_NYT,
    component: () => import('@/main/views/oversigtsmodul/OpretNyt.vue'),
    props: true,
    meta: {
      title: MainRoute.OPRET_INDKOEB,
      description: MetaDescription.OPRET_NYT,
      requiredOrganisationsProfil: true,
      isPublic: false,
      requiredAuth: true
    }
  },
  {
    path: Path.AFMELD_AGENT,
    component: () => import('@/main/views/AfmeldUdbudsagentSide.vue'),
    props: true,
    meta: {
      isPublic: true
    }
  },
  {
    path: Path.ARKIV,
    name: MainRoute.ARKIV,
    component: () => import('@/main/views/arkivmodul/ArkivOversigt.vue'),
    props: true,
    meta: {
      title: MainRoute.ARKIV,
      description: MetaDescription.ARKIV,
      isPublic: true
    }
  },
  {
    path: Path.OPRET_NYT,
    name: MainRoute.OPRET_INDKOEB,
    component: () => import('@/main/views/udbudsmodul/OpretUdbud.vue'),
    meta: {
      requiredOrganisationsProfil: true,
      description: MetaDescription.OPRET_NYT,
      isPublic: false,
      requiredAuth: true
    },
    children: [
      {
        path: Path.UDBUDS_BEKENDGOERELSE,
        component: () => import('@/main/components/eForms/formular/EFormsNoticeType.vue'),
        meta: {
          title: MainRoute.OPRET_INDKOEB,
          description: MetaDescription.OPRET_NYT,
          requiredOrganisationsProfil: true
        },
        props: beregnPropsForNoticeType('DKE3')
      },
      {
        path: Path.FORVENTET_INDKOEB,
        component: () => import('@/main/components/eForms/formular/EFormsNoticeType.vue'),
        meta: {
          title: MainRoute.OPRET_INDKOEB,
          description: MetaDescription.OPRET_NYT,
          requiredOrganisationsProfil: true
        },
        props: beregnPropsForNoticeType('DKE0')
      },
      {
        path: Path.UDBUDS_BEKENDGOERELSE_BLANK_AENDRINGSBEKENDTGOERELSE,
        component: () => import('@/main/views/EFormsFormular/EFormsUdbudsbekendtgoerelseBlankAendringsbekendtgoerelse.vue'),
        meta: {
          title: MainRoute.OPRET_AENDRING,
          description: MetaDescription.OPRET_AENDRING,
          requiredOrganisationsProfil: true
        },
        props: beregnPropsForNoticeType('DKE3')
      },
      {
        path: Path.WAS,
        redirect: () => (window.location.href = 'https://www.was.digst.dk/udbud-dk')
      },
      {
        path: Path.UDBUDS_BEKENDGOERELSE_AENDRINGSBEKENDTGOERELSE,
        component: () => import('@/main/views/EFormsFormular/EFormsUdbudsbekendtgoerelseAendringsbekendtgoerelse.vue'),
        props: route => ({
          noticeType: 'DKE3',
          aendringAfNoticeId: route.query.aendringAfNoticeId,
          aendringAfNoticeVersion: route.query.aendringAfNoticeVersion,
          aendringAfNoticePublicationNumber: route.query.aendringAfNoticePublicationNumber,
          kladdeNoegle: {
            noticeId: {
              value: route.query.noticeId
            },
            noticeVersion: {
              value: route.query.noticeVersion
            }
          } as BekendtgoerelseNoegle
        }),
        meta: {
          title: MainRoute.OPRET_AENDRING,
          description: MetaDescription.OPRET_AENDRING,
          requiredOrganisationsProfil: true
        }
      },
      {
        path: Path.FORVENTET_INDKOEB_AENDRING,
        component: () => import('@/main/views/EFormsFormular/EFormsUdbudsbekendtgoerelseAendringsbekendtgoerelse.vue'),
        props: route => ({
          noticeType: 'DKE0',
          aendringAfNoticeId: route.query.aendringAfNoticeId,
          aendringAfNoticeVersion: route.query.aendringAfNoticeVersion,
          aendringAfNoticePublicationNumber: route.query.aendringAfNoticePublicationNumber,
          kladdeNoegle: {
            noticeId: {
              value: route.query.noticeId
            },
            noticeVersion: {
              value: route.query.noticeVersion
            }
          } as BekendtgoerelseNoegle
        }),
        meta: {
          title: MainRoute.OPRET_AENDRING,
          description: MetaDescription.OPRET_AENDRING,
          requiredOrganisationsProfil: true
        }
      },
      {
        path: Path.KVITTERING,
        component: () => import('@/main/views/EFormsFormular/EFormsKvittering.vue'),
        props: route => ({
          noticeId: route.query.noticeId,
          noticeVersion: route.query.noticeVersion,
          noticePublicationNumber: route.query.noticePublicationNumber
        }),
        meta: {
          title: MainRoute.KVITTERING,
          description: MetaDescription.KVITTERING,
          requiredOrganisationsProfil: true
        }
      }
    ]
  },
  {
    path: Path.HJAELP,
    name: InformationsideRoute.HJAELPESIDE,
    component: () => import('@/main/views/Hjaelpesider.vue'),
    meta: {
      isPublic: true,
      title: InformationsideRoute.HJAELPESIDE,
      description: MetaDescription.HJAELPESIDE
    },
    children: [
      {
        path: Path.HJAELP_CONTENTFULNOEGLE_NIVEAU2,
        component: () => import('@/main/views/HjaelpeOpsamling.vue'),
        props: true,
        children: [
          {
            path: Path.HJAELP_CONTENTFULNOEGLE_NIVEAU3,
            component: () => import('@/main/views/HjaelpeOpsamling.vue'),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: Path.S9_AUTH_REDIRECT,
    name: 's9-auth-redirect',
    props: true,
    meta: {
      isPublic: true
    },
    component: () => import('@/main/views/S9AuthRedirect.vue')
  },
  {
    path: Path.SHOW_ME,
    name: 'showMe',
    props: false,
    meta: {
      isPublic: true
    },
    component: () => import('@/main/views/ShowMe.vue')
  },
  {
    path: Path.UNAUTHORIZED,
    name: CommonRoute.UNAUTHORIZED,
    component: () => import('@/main/views/UnauthorizedPage.vue'),
    meta: {
      title: CommonRoute.UNAUTHORIZED,
      isPublic: true
    }
  },
  {
    path: Path.ERROR_FEJLBESKED,
    name: CommonRoute.ERROR,
    component: () => import('@/main/views/ErrorPage.vue'),
    props: true,
    meta: {
      title: CommonRoute.ERROR,
      isPublic: true
    }
  },
  {
    path: Path.NOT_FOUND,
    name: CommonRoute.NOT_FOUND,
    component: () => import('@/main/views/NotFoundPage.vue'),
    meta: {
      title: CommonRoute.NOT_FOUND,
      isPublic: true
    }
  },
  {
    path: Path.REFRESH,
    name: 'refresh',
    component: () => '',
    async beforeEnter() {
      /**
       * Bruges til forlænge login
       */
      const { pathname } = window.location;
      const redirectPath = pathname.startsWith('/udbud') ? pathname.replace('/udbud', '') : pathname;
      await authState.state?.login({ redirectPath });
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/main/views/NotFoundPage.vue')
  }
];

export default defaultRoutes;

function beregnPropsForNoticeType(noticeSubTypeId: string): (route: any) => Partial<EFormsNoticeTypeProps> {
  return (route: any) => {
    const defaultValues = {
      'BT-01-notice': route.query.bt01notice ?? 'other-goi'
    };
    if (route.query.noticeId !== undefined && route.query.noticeVersion) {
      return {
        noticeSubTypeId: noticeSubTypeId,
        kladdeNoegle: {
          noticeId: {
            value: route.query.noticeId as string
          },
          noticeVersion: {
            value: route.query.noticeVersion as string
          }
        }
      };
    } else {
      return {
        defaultValues: defaultValues,
        noticeSubTypeId: noticeSubTypeId
      };
    }
  };
}

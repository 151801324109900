/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { BrugerprofilDto, OpretBrugerprofilDto } from '../../../models/generated';

export const getBrugerprofilController = () => {
  /**
 * Brugere har ikke mulighed for at ændre/oprette deres navn i brugerprofilen, da navnet trækkes automatisk fra deres login, og brugeren persisteres permanent som en handlingsaktør (unik på entitetsID).
Hvis de ønsker at ændre navnet, skal det ændres i IDP'en, og derefter ændres det automatisk.
 * @summary Redigerer brugerprofilen for brugeren, som har en gyldig JWT, der er logget ind.
 */
  const rediger = <TData = AxiosResponse<BrugerprofilDto>>(
    opretBrugerprofilDto: OpretBrugerprofilDto,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.put(`/brugerprofil`, opretBrugerprofilDto, options);
  };
  /**
 * Oprettelse sker i forbindelse med indlogning, hvor den indloggede bruger ikke er tilknyttet en eksisterende brugerprofil.

 * @summary Opretter brugerprofilen for brugeren, som har en gyldig JWT, der er logget ind.
 */
  const opret = <TData = AxiosResponse<BrugerprofilDto>>(
    opretBrugerprofilDto: OpretBrugerprofilDto,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/brugerprofil`, opretBrugerprofilDto, options);
  };
  /**
   * @summary Henter brugerprofilen for brugeren, som har en gyldig JWT, der er logget ind.
   */
  const hentPaaLoggetBrugerprofil = <TData = AxiosResponse<BrugerprofilDto>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/brugerprofil:aktuel`, options);
  };
  /**
   * @summary Henter brugerprofilen for brugeren med EntitetsId, bruges til systemkontrol.
   */
  const hentBrugerByEntitetsId = <TData = AxiosResponse<BrugerprofilDto>>(entitetsId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/brugerprofil/${entitetsId}`, options);
  };
  return { rediger, opret, hentPaaLoggetBrugerprofil, hentBrugerByEntitetsId };
};
export type RedigerResult = AxiosResponse<BrugerprofilDto>;
export type OpretResult = AxiosResponse<BrugerprofilDto>;
export type HentPaaLoggetBrugerprofilResult = AxiosResponse<BrugerprofilDto>;
export type HentBrugerByEntitetsIdResult = AxiosResponse<BrugerprofilDto>;

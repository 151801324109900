import { extend } from '@vue/shared';
import * as DKFDS from 'dkfds';
import { defineStore } from 'pinia';
import { Ref } from 'vue';

import BrugerProfilOprettetModalWindow from '@/main/components/modals/BrugerProfilOprettetModalWindow.vue';
import BrugerProfilOprettelseModalWindow from '@/main/components/modals/BrugerprofilOprettelseModalWindow.vue';
import EFormsUploadInProgressModalWindow from '@/main/components/modals/EFormsUploadInProgressModalWindow.vue';
import LoginModalWindow from '@/main/components/modals/LoginModalWindow.vue';
import LoginUdloeberModalWindow from '@/main/components/modals/LoginUdloeberModalWindow.vue';
import OpretAnnonceModalWindow from '@/main/components/modals/OpretAnnonceModalWindow.vue';
import PrivatPersonNagModalWindow from '@/main/components/modals/PrivatPersonNagModalWindow.vue';
import UdbudsAgentSletningBekraeftelseModalWindow from '@/main/components/modals/UdbudsAgentSletningBekraeftelseModalWindow.vue';
import GemSoegningModalWindow from '@/main/components/soegning/GemSoegningModalWindow.vue';
import SoegningSorteringMobilModal from '@/main/components/soegning/SoegningSorteringMobilModal.vue';
import TipsTilSoegningModalWindow from '@/main/components/soegning/TipsTilSoegningModalWindow.vue';
import FilterMobil from '@/main/components/soegning/filter/FilterMobil.vue';
import { DriftsmeddelelseDto, GemtSoegningDTO } from '@/main/models/generated';
import DriftmeddelelseRedigeringBekraeftelseVue from '@/main/views/konfigurationsmodul/modals/DriftmeddelelseRedigeringBekraeftelse.vue';
import DriftmeddelelseSletningBekraeftelseVue from '@/main/views/konfigurationsmodul/modals/DriftmeddelelseSletningBekraeftelse.vue';
import DriftsmeddelelseOprettelseVue from '@/main/views/konfigurationsmodul/modals/DriftsmeddelelseOprettelse.vue';

import IndkoebsoverblikSletningBekraeftelseModalWindow from '../components/modals/IndkoebsoverblikSletningBekraeftelseModalWindow.vue';
import SoegningGemtModalWindow from '../components/soegning/SoegningGemtModalWindow.vue';

const component = extend({});
type VueComponent = InstanceType<typeof component>;

export interface IModalProps {
  component: null | VueComponent;
  props?: object;
}

export interface IModalState {
  modalState: IModalProps;
  visSpinner: boolean;
  spinnerLabel: string;
  modalId: string | null;
  showCloseButton: boolean;
  closeOnClickOutside: boolean;
  onPageBottom: boolean;
  filterStyling: boolean;
}

const basicState = { component: null, props: {} };

export const useModalStore = defineStore('modal-store', {
  state: (): IModalState => ({
    modalState: basicState,
    modalId: null,
    showCloseButton: true,
    closeOnClickOutside: true,
    onPageBottom: false,
    filterStyling: false,
    visSpinner: false,
    spinnerLabel: ''
  }),
  actions: {
    openModal(
      payload: IModalProps,
      modalId: string,
      showCloseButton = true,
      closeOnClickOutside = true,
      onPageBottom = false,
      filterStyling = false
    ) {
      // Get props and component from payload passed to function
      const { props, component } = payload;

      // Get the body element
      const body = document.body;

      // Close all tooltips before opening modal
      const tooltipElement = document.getElementsByClassName('.js-tooltip');
      if (tooltipElement.length > 0) {
        new DKFDS.Tooltip(tooltipElement[0]).closeAll();
      }

      // If its there, prevent scroll from happening
      if (body) {
        body.classList.add('modal-open');
      }

      // Assign them to our state
      this.showCloseButton = showCloseButton;
      this.closeOnClickOutside = closeOnClickOutside;
      this.onPageBottom = onPageBottom;
      this.filterStyling = filterStyling;
      this.modalId = modalId;
      this.modalState = { component, props: props ?? {} };
    },
    openModalWithSpinner(spinnerLabel?: string) {
      // Get the body element
      const body = document.body;
      // If its there, prevent scroll from happening
      if (body) {
        body.classList.add('modal-open');
      }
      this.visSpinner = true;
      this.spinnerLabel = spinnerLabel ?? 'modal.spinner.default';
    },
    closeModal() {
      // Reset our state
      this.modalState = basicState;

      // Get the body element
      const body = document.body;

      // If its there, reset overflow style
      if (body) {
        body.classList.remove('modal-open');
      }
      this.visSpinner = false;
    },
    openLoginModal(props: object) {
      this.openModal(
        {
          component: LoginModalWindow,
          props
        },
        'login'
      );
    },
    openGemSoegningModal() {
      this.openModal(
        {
          component: GemSoegningModalWindow,
          props: {}
        },
        'gemSoegning'
      );
    },
    openSoegningGemtModal() {
      this.openModal(
        {
          component: SoegningGemtModalWindow,
          props: {}
        },
        'soegningGemt'
      );
    },
    openOpretAnnonceModal() {
      this.openModal(
        {
          component: OpretAnnonceModalWindow,
          props: {}
        },
        'OpretAnnonceModalWindow'
      );
    },
    openTipsTilSoegningModal() {
      this.openModal(
        {
          component: TipsTilSoegningModalWindow,
          props: {}
        },
        'TipsTilSoegningModalWindow'
      );
    },
    openUdbudsAgentSletningBekraeftelseModal(dto: GemtSoegningDTO) {
      this.openModal(
        {
          component: UdbudsAgentSletningBekraeftelseModalWindow,
          props: {
            element: dto
          }
        },
        'bekraeft-slet-udbudsagent'
      );
    },
    openIndkoebsoverblikSletningBekraeftelseModal(
      noticeId: string,
      noticeVersion: string,
      tableData: AnnonceOverblikTableRow[],
      dropdownIndex: number | null,
      titel: AnnonceOverblikTableRow
    ) {
      this.openModal(
        {
          component: IndkoebsoverblikSletningBekraeftelseModalWindow,
          props: {
            noticeId,
            noticeVersion,
            tableData,
            dropdownIndex,
            titel
          }
        },
        'bekraeft-slet-kladde'
      );
    },
    openPrivatPersonNagModal() {
      this.openModal(
        {
          component: PrivatPersonNagModalWindow,
          props: {}
        },
        'login.modal.privat-person',
        false,
        false
      );
    },
    openBrugerprofilOprettelseModal() {
      this.openModal(
        {
          component: BrugerProfilOprettelseModalWindow,
          props: {}
        },
        'brugerprofil',
        false,
        false
      );
    },
    openBrugerprofilOprettetModal() {
      this.openModal(
        {
          component: BrugerProfilOprettetModalWindow,
          props: {}
        },
        'brugerprofil-oprettet',
        true,
        true
      );
    },
    openSoegningFilterMobilModal() {
      this.openModal(
        {
          component: FilterMobil,
          props: {}
        },
        'soegning.filter.mobil',
        false,
        true,
        false,
        true
      );
    },
    openSoegningSorteringMobilModal() {
      this.openModal(
        {
          component: SoegningSorteringMobilModal,
          props: {}
        },
        'soegning.sorter.mobil',
        false,
        true,
        true
      );
    },
    openDriftsmeddelelseOprettelse(dto: DriftsmeddelelseDto) {
      this.openModal(
        {
          component: DriftsmeddelelseOprettelseVue,
          props: {
            element: dto
          }
        },
        'internal.driftsmeddelelese-oprettelse'
      );
    },
    openDriftsmeddelelseRedigeringBekraeftelse(dto: DriftsmeddelelseDto) {
      this.openModal(
        {
          component: DriftmeddelelseRedigeringBekraeftelseVue,
          props: {
            element: dto
          }
        },
        'internal.driftsmeddelelese-redigering-bekraftelse'
      );
    },
    openDriftsmeddelelseSletningBekraeftelse(dto: DriftsmeddelelseDto) {
      this.openModal(
        {
          component: DriftmeddelelseSletningBekraeftelseVue,
          props: {
            element: dto
          }
        },
        'internal.driftsmeddelelese-sletning-bekraftelse'
      );
    },
    openUploadLoadingModal() {
      this.openModal(
        {
          component: EFormsUploadInProgressModalWindow,
          props: {}
        },
        '',
        false,
        false
      );
    },
    openLoginExpiringModal() {
      this.openModal(
        {
          component: LoginUdloeberModalWindow
        },
        'login.modal.udloeber',
        false,
        false
      );
    }
  },
  getters: {}
});

export enum Path {
  BASE = '/',
  EMPTY = '',
  PLAYGROUND = '/playground',
  DEMO = '/demo',
  DEMO_EFORMS_FELTER_DEMO = '/demo/eFormsFelterDemo',
  DEMO_EFORMS_NOTICETYPE_DEMO = '/demo/eFormsNoticeTypeDemo',
  INFORMATION = '/information/:infosideId',
  INDSTILLINGER = '/indstillinger',
  PROFIL = 'profil',
  INDSTILLINGER_PROFIL = '/indstillinger/profil',
  KONFIG = '/konfig',
  SYNONYMER = 'synonymer',
  KONGIG_SYNONYMER = '/konfig/synonymer',
  REWRITES = 'rewrites',
  KONGIG_REWRITES = '/konfig/rewrites',
  STOPORD = 'stopord',
  KONGIG_STOPORD = '/konfig/stopord',
  VAEGTNING = 'vaegtning',
  KONGIG_VAEGTNING = '/konfig/vaegtning',
  DRIFTMEDDELELSE = 'driftsmeddelelse',
  KONFIG_DRIFTMEDDELELSE = '/konfig/driftsmeddelelse',
  // Hvis denne ændres her, skal den også ændres i BygEmailService.java
  SOEG = '/soeg',
  ARKIV = '/arkiv',
  DETALJEVISNING = '/detaljevisning',
  // Hvis denne ændres her, skal den også ændres i BygEmailService.java
  AFMELD_AGENT = '/afmeldUdbudsagent',
  OPRET_NYT = '/opretIndkoeb',
  UDBUDS_BEKENDGOERELSE = 'udbudsbekendtgoerelse',
  UDBUDS_BEKENDGOERELSE_BLANK_AENDRINGSBEKENDTGOERELSE = 'udbudsbekendtgoerelseBlankAendring',
  UDBUDS_BEKENDGOERELSE_AENDRINGSBEKENDTGOERELSE = 'udbudsbekendtgoerelseAendring',
  OPRET_UDBUD_UDBUDS_BEKENDGOERELSE = '/opretIndkoeb/udbudsbekendtgoerelse',
  FORVENTET_INDKOEB = 'forventetindkoeb',
  OPRET_UDBUD_FORVENTET_INDKOEB = '/opretIndkoeb/forventetindkoeb',
  OPRET_UDBUD_UDBUDS_BEKENDGOERELSE_BLANK_AENDRINGSBEKENDTGOERELSE = '/opretIndkoeb/udbudsbekendtgoerelseBlankAendring',
  OPRET_UDBUD_UDBUDS_BEKENDGOERELSE_AENDRINGSBEKENDTGOERELSE = '/opretIndkoeb/udbudsbekendtgoerelseAendring',
  OPRET_UDBUD_FORVENTET_INDKOEB_AENDRINGSBEKENDTGOERELSE = '/opretIndkoeb/forventetindkoebAendring',
  FORVENTET_INDKOEB_AENDRING = 'forventetindkoebAendring',
  KVITTERING = 'kvittering',
  OPRET_UDBUD_KVITTERING = '/opretIndkoeb/kvittering',
  HJAELP = '/hjaelp',
  HJAELP_SOEG = '/hjaelp/hjaelpSoeg',
  HJAELP_CONTENTFULNOEGLE_NIVEAU2 = ':contentfulNoegle',
  HJAELP_CONTENTFULNOEGLE_NIVEAU3 = ':contentfulNoegle2',
  S9_AUTH_REDIRECT = '/s9-auth-redirect',
  UNAUTHORIZED = '/unauthorized',
  ERROR_FEJLBESKED = '/error/:fejlbesked',
  NOT_FOUND = '/notfound',
  REFRESH = '/refresh',
  UDBUDSAGENT = '/agent',
  ORDREGIVER = '/ordregiver',
  SHOW_ME = '/showMe',
  WAS = '/was'
}

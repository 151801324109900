<template>
  <div :class="{ 'form-error': visFejl }" class="form-group">
    <FeltLabel :for-id="inputFeltId" :text-prefix="textPrefix" :er-e-forms="erEForms" :name="name" />
    <span v-if="visFejl" id="rich-tekst-error" class="form-error-message">
      <span class="sr-only">{{ $t('fejlmeddelelse-error-label') }}</span> {{ $t((customErrormessages ? textPrefix : 'input') + '.' + errors[0]!) }}
    </span>
    <div aria-label="rich-tekst-input" class="form-input rich-tekst-container">
      <TipTapWrapper
        ref="editor"
        :include-links="includeLinks"
        :preselected-value="preselectedValue"
        :text-prefix="textPrefix"
        :input-felt-id="inputFeltId"
        @blur="handleOnBlur"
        @update="handleOnInput" />
      <AntalTegnHint
        v-if="maxlength != null && maxlength > 0 && antalTegn.tegn > 0"
        ref="antalHint"
        :antal-tegn="antalTegn.tegn"
        :maxlength="maxlength" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { onMounted, reactive, ref } from 'vue';

import AntalTegnHint from '@/main/components/input/AntalTegnHint.vue';
import FeltLabel from '@/main/components/input/FeltLabel.vue';
import TipTapWrapper from '@/main/components/input/TipTapWrapper.vue';

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  textPrefix: {
    type: String,
    required: true
  },
  erEForms: {
    type: Boolean,
    default: false
  },
  customErrormessages: {
    type: Boolean,
    default: false
  },
  includeLinks: {
    type: Boolean,
    default: false
  },
  maxlength: {
    type: Number,
    default: null
  },
  preselectedValue: {
    type: String,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: () => ''
  }
});

const editor: TipTapWrapper = ref(typeof TipTapWrapper);
const antalHint: AntalTegnHint = ref(typeof AntalTegnHint);
const visFejl = ref(false);
const inputFeltId = ref(props.textPrefix.concat('-id'));
const name = ref(props.name);
const { meta, errors, handleBlur, handleChange, setValue, validate } = useField(name, props.rules);
const antalTegn = reactive({ tegn: 0 });

onMounted(() => {
  setValue(editor.value.getTrimmed(), false);
});

function checkFejl() {
  visFejl.value = !meta.valid && meta.touched && errors.value.length > 0;
}

function handleOnInput(e: Event) {
  handleChange(e, false);
  setValue(editor.value.getTrimmed(), false);
  updateAntalTegn();
}

function handleOnBlur(e: Event) {
  handleBlur(e, false);
  validate().then(() => checkFejl());
}

function updateAntalTegn() {
  antalTegn.tegn = editor.value.getTrimmed().length;
}

function getHtml(): string {
  return editor.value.getHtml();
}

defineExpose({
  getHtml
});
</script>
<style lang="scss" scoped>
@import '../../styles/input';
</style>

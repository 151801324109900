import hljsVuePlugin from '@highlightjs/vue-plugin';
import axios from 'axios';
import * as DKFDS from 'dkfds';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import xml from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/stackoverflow-light.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { defineRule } from 'vee-validate';
import { createApp } from 'vue';

import clickOutside from '@/main/directives/clickOutside';
import { CommonRoute } from '@/main/enums/commonRoute.enum';
import router from '@/main/router';
import { sprogService } from '@/main/services/sprog.service';
import { authState } from '@/main/stores/auth.state';
import { useErrorStore } from '@/main/stores/error.state';
import '@/main/styles/dkfds-mitudbud.scss';

import App from './App.vue';
import Validation from './utils/validation';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
// Axios interceptoren er vores globale errorhandler. Denne bliver kørt hver gang der kommer et axios response eller erorr.
// Tilføj true eller false i diverse getConfig axios kald for at enten håndtere fejlen lokalt eller på et global niveau.
// Hvis 'false' bliver det håndteret lokalt. Hvis 'true' bliver det håndteret globalt.
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const setHaandterFejlGlobalt = error.config.haandterFejlGlobalt;
    const errorStore = useErrorStore();
    errorStore.opdaterHaandterFejlGlobalt(setHaandterFejlGlobalt);
    if (setHaandterFejlGlobalt) {
      const fejlbesked = error.code;
      router.push({
        name: CommonRoute.ERROR,
        params: { fejlbesked }
      });
    }
    return Promise.reject(error);
  }
);
app.use(router);
app.use(pinia);
app.use(sprogService.sprogservice);
window.pinia = pinia;
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('json', json);
app.use(hljsVuePlugin);
defineRule('required', (value: string) => Validation.required(value));
defineRule('email', (value: string) => Validation.email(value));
defineRule('phone', (value: string) => Validation.phone(value));
defineRule('max', (value: string, [max]) => Validation.max(value, max));
defineRule('valid24hours', (value: string) => Validation.valid24hours(value));
defineRule('pattern', (value: string, [pattern]) => Validation.pattern(value, pattern));
defineRule('filterdateAfter', (value: string, [target]) => Validation.filterdateAfter(value, target));
defineRule('filterdateBefore', (value: string, [target]) => Validation.filterdateBefore(value, target));
defineRule('validDate', (value: string, [target]) => Validation.validDate(value, target));
defineRule('dateTodayOrAfter', (value: string) => Validation.dateTodayOrAfter(value));
defineRule('integer', (value: string) => Validation.integer(value));
defineRule('currency', (value: string) => Validation.currency(value));
defineRule('number', (value: string) => Validation.number(value));
defineRule('numberTime', (value: string) => Validation.numberTime(value));
defineRule('dateTimeAfterNow', (value: string, [target]) => Validation.dateTimeAfterNow(value, target));
defineRule('dateTimeAfter', (value: string, args: string[]) => Validation.dateTimeAfter(value, args));
app.directive('clickOutside', clickOutside);
async function init(): Promise<void> {
  await authState.initState();
  await Promise.all([sprogService.initSprogservice()]);
  console.log('Henter sprog færdig');
  DKFDS.init();
}
(async () => {
  await init();
  app.mount('#app');
  await authState.hentBrugerprofilVedLoginOgVisModalHvisMangler();
})();

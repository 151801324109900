<template>
  <div>
    <div v-if="isLoaded">
      <BaseCheckboxGroup
        name="checkbox-small"
        :options="kodelisteIndhold"
        @filter-checkbox-update="setFilterChild"
        :preselected-values="preselectedValues" />
    </div>
    <div v-else>
      <LoadingSpinner :loading-text="'formular.publicering.igang'" :timer-length="200" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { indexOf } from 'lodash';
import { PropType, computed, onMounted, ref } from 'vue';

import LoadingSpinner from '@/main/components/LoadingSpinner.vue';
import BaseCheckboxGroup from '@/main/components/base/BaseCheckboxGroup.vue';
import { CodeListFilterKonfigurationNavn } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';
import { useEFormsKodelisterStore } from '@/main/stores/eforms-kodelister.state';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { filterUtil } from '@/main/utils/filter-util';
import { PROCUREMENT_PROCEDURE_ORDER } from '@/main/utils/filter-util';

const props = defineProps({
  typeOfFilter: {
    type: String,
    required: false
  },
  options: {
    type: Array as PropType<RadioOgCheckboxModel[]>,
    required: false
  },
  index: {
    type: Number,
    required: true
  },
  preselectedValues: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  konfigurationNavn: {
    type: String as PropType<CodeListFilterKonfigurationNavn>,
    required: true
  }
});

const kodelisteIndhold = ref<RadioOgCheckboxModel[]>([]);
const isLoaded = ref(false);
const soegningStore = useSoegningValgStore(window.pinia);
const preselectedValues = computed(() => soegningStore.getSelectedValues(props.konfigurationNavn));
const eformsKodelisterStore = useEFormsKodelisterStore();

const emit = defineEmits<{
  codeListToCheckboxChanged: [selectedValues: string[]];
}>();

onMounted(async () => {
  const selectedValues = soegningStore.getSelectedValues(props.konfigurationNavn);
  if (props.typeOfFilter !== undefined) {
    const values = await eformsKodelisterStore.hentEFormsKodelisterFraKodelisteId(props.typeOfFilter);
    const tiebreaker = getTiebreaker(props.typeOfFilter);
    const sortFunc = filterUtil.sortFilterFunc(selectedValues, tiebreaker);
    kodelisteIndhold.value = values.sort(sortFunc);
  } else if (props.options !== undefined) {
    // Vi vil gerne beholde den orginale rækkefølge, blandt øvrige resultater, da den er betydnignsfuld
    const sortFunc = filterUtil.sortFilterFunc(
      selectedValues,
      (a: RadioOgCheckboxModel | DropdownOption, b: RadioOgCheckboxModel | DropdownOption) => {
        const aIndex = indexOf(props.options, a);
        const bIndex = indexOf(props.options, b);
        return aIndex - bIndex;
      }
    );
    // NB: undgå at mutere orginalt array.
    kodelisteIndhold.value = props.options.toSorted(sortFunc);
  } else {
    throw new Error('Der skal enten angives typeOfFilter eller options');
  }
  isLoaded.value = true;
});

function getTiebreaker(typeOfFilter: string) {
  if (typeOfFilter !== 'procurement-procedure-type') {
    return undefined;
  }

  // Sortering af Indkøbsprocesser
  return (a: DropdownOption | RadioOgCheckboxModel, b: DropdownOption | RadioOgCheckboxModel): number => {
    const aKey = a.key;
    const bKey = b.key;
    if (aKey == null || bKey == null) {
      return 0;
    }
    const aIndex = PROCUREMENT_PROCEDURE_ORDER.indexOf(aKey);
    const bIndex = PROCUREMENT_PROCEDURE_ORDER.indexOf(bKey);
    if (aIndex === -1 || bIndex === -1) {
      return 0;
    }
    return aIndex - bIndex;
  };
}

function setFilterChild(values: string[]) {
  soegningStore.updateCodelistToCheckboxFilter(props.konfigurationNavn, values);
  emit('codeListToCheckboxChanged', values);
}
</script>

import { EnvSpecificSpaConfig, StaticSpaConfig } from '@/main/models/spaConfigDto';

/**
 * SpaConfig indholder konfigurationer som skal bruges til at sikre SPA'en oprfører sig korrekt.
 * Består at static (delte) og miljøspecifikke konfigurationer.
 */
export class SpaConfig {
  public static readonly staticConfig: StaticSpaConfig = {
    baseUrl: {
      adfaerd: '/adfaerd/',
      bruger: '/bruger/',
      konfiguration: '/konfiguration/',
      soegning: '/soegning/',
      udbud: '/udbud/'
    },
    sector9: {
      redirectEndpoint: '/s9-auth-redirect'
    }
  };

  public static readonly localConfig: EnvSpecificSpaConfig = {
    baseUrl: {
      client: 'https://lokal.udbud.dk'
    },
    sector9: {
      authUrl: 'https://localhost.erstdev.dk/auth',
      tokenUrl: 'https://localhost.erstdev.dk/auth/token',
      mitIdClient: 'AAIABsxVjQotqEvbtKSNM9OpK3Y',
      mitudbudeidasnatural: 'AAIABsxVjQotqEvbtKSNM9OpK3Y_eidas_natural',
      mitudbudeidasLegal: 'AAIABsxVjQotqEvbtKSNM9OpK3Y_eidas_legal',
      mitudbudssi: 'AAIABsxVjQotqEvbtKSNM9OpK3Y_ssi'
    }
  };

  public static readonly devConfig: EnvSpecificSpaConfig = {
    baseUrl: {
      client: 'https://dev.udbud.dk'
    },
    sector9: {
      authUrl: 'https://erstdev.virk.dk/auth',
      tokenUrl: 'https://erstdev.virk.dk/auth/token',
      mitIdClient: 'AAIABsxVjQotqEvbtKSNM9OpK3Y',
      mitudbudeidasnatural: 'AAIABsxVjQotqEvbtKSNM9OpK3Y_eidas_natural',
      mitudbudeidasLegal: 'AAIABsxVjQotqEvbtKSNM9OpK3Y_eidas_legal',
      mitudbudssi: 'AAIABsxVjQotqEvbtKSNM9OpK3Y_ssi'
    }
  };

  public static readonly testConfig: EnvSpecificSpaConfig = {
    baseUrl: {
      client: 'https://test2.udbud.dk'
    },
    sector9: {
      authUrl: 'https://ersttest.virk.dk/auth',
      tokenUrl: 'https://ersttest.virk.dk/auth/token',
      mitIdClient: 'AAIABuOXWZPxAErdmw6HPyB0LyQ',
      mitudbudeidasnatural: 'AAIABuOXWZPxAErdmw6HPyB0LyQ_eidas_natural',
      mitudbudeidasLegal: 'AAIABuOXWZPxAErdmw6HPyB0LyQ_eidas_legal',
      mitudbudssi: 'AAIABuOXWZPxAErdmw6HPyB0LyQ_ssi'
    }
  };

  public static readonly demoConfig: EnvSpecificSpaConfig = {
    baseUrl: {
      client: 'https://demo.udbud.dk'
    },
    sector9: {
      authUrl: 'https://erstpreprod.virk.dk/auth',
      tokenUrl: 'https://erstpreprod.virk.dk/auth/token',
      mitIdClient: 'AAIABglWc12NDk-4keEKXDfEcXw',
      mitudbudeidasnatural: 'AAIABglWc12NDk-4keEKXDfEcXw_eidas_natural',
      mitudbudeidasLegal: 'AAIABglWc12NDk-4keEKXDfEcXw_eidas_legal',
      mitudbudssi: 'AAIABglWc12NDk-4keEKXDfEcXw_ssi'
    }
  };

  public static readonly preprodConfig: EnvSpecificSpaConfig = {
    baseUrl: {
      client: 'https://preprod.udbud.dk'
    },
    sector9: {
      authUrl: 'https://erstpreprod.virk.dk/auth',
      tokenUrl: 'https://erstpreprod.virk.dk/auth/token',
      mitIdClient: 'AAIABglWc12NDk-4keEKXDfEcXw',
      mitudbudeidasnatural: 'AAIABglWc12NDk-4keEKXDfEcXw_eidas_natural',
      mitudbudeidasLegal: 'AAIABglWc12NDk-4keEKXDfEcXw_eidas_legal',
      mitudbudssi: 'AAIABglWc12NDk-4keEKXDfEcXw_ssi'
    }
  };

  public static readonly prodConfig: EnvSpecificSpaConfig = {
    baseUrl: {
      client: 'https://udbud.dk'
    },
    sector9: {
      authUrl: 'https://erst.virk.dk/auth',
      tokenUrl: 'https://erst.virk.dk/auth/token',
      mitIdClient: 'AAIABqvXGfDOaEP7q8TaMniYJp0',
      mitudbudeidasnatural: 'AAIABqvXGfDOaEP7q8TaMniYJp0_eidas_natural',
      mitudbudeidasLegal: 'AAIABqvXGfDOaEP7q8TaMniYJp0_eidas_legal',
      mitudbudssi: 'AAIABqvXGfDOaEP7q8TaMniYJp0_ssi'
    }
  };
}
